import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http'
import { Observable, throwError } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';

import { AuthService } from '../../auth/services/auth.service';

import { AuthLoginResponse, IAuthLoginResponse } from '../../auth/models/auth.login.response'

@Injectable()
export class HttpWrapperService {

  constructor(public http: HttpClient, public auth: AuthService) { }

  private refreshAndRetry (method: string, url: string, options: any): Observable<any> {
    return this.auth.reAuth()
      .pipe(catchError(error => throwError(error)))
      .pipe(mergeMap(_ => this.request(method, url, options, false)));
  }

  public request (method: string, url: string, options: any, shouldRetry = true): Observable<any> {
    let accessToken = this.auth.getAccessToken();
    if (accessToken) {
      if (!options.headers) {
        options.headers = new HttpHeaders();
      }

      options.headers = options.headers.append('Authorization', `Bearer ${ accessToken }`);
    }

    return this.http.request(method, url, options)
    .pipe(catchError(error => {
      if (error.status === 401 && shouldRetry) {
        return this.refreshAndRetry(method, url, options);
      }
      return throwError(error);
    }));
  }
}
