import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, Subject } from 'rxjs';

import { ConfigService } from '../../core/services/config.service';
import { DialogService } from '../../notices/services/dialog.service';
import { HttpWrapperService } from './http.wrapper.service';
import { SessionService } from './session.service';
import { StorageService } from '../../core/services/storage.service';

import {
  MyTenantApiClientService,
  IBindToExternalIdentityProviderRequest,
  IIdentityTenant,
} from '../../../api/mytenant/index';

const AUTH_KEY: string = 'authenticationCode';
const BIND_KEY: string = 'externalBindCode';
const STATE_KEY: string = 'authenticationState';
const AAD_IDENTITY_PROVIDER: string = 'AzureAD';
const GLOODOO_IDENTITY_PROVIDER: string = 'gloodoo';

@Injectable()
export class ActiveDirectoryService {
  private aadEnabled: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private tenantId: string;
  private applicationId: string;
  private host: string = 'login.microsoftonline.com';
  private graphResource: string = 'https://graph.windows.net';
  private authenticationCode: string;
  private externalBindCode: string;
  private state: string;

  private signinParams: Array<string> = [
    'scope=openid'
  ];

  private authorisationParams: Array<string> = [
    'prompt=admin_consent',
    `resource=${ encodeURIComponent(this.graphResource) }`
  ];

  constructor(
    private configService: ConfigService,
    private dialogService: DialogService,
    private httpWrapper: HttpWrapperService,
    private myTenantApi: MyTenantApiClientService,
    private sessionService: SessionService,
    private storageService: StorageService
  ) {
    this.applicationId = this.configService.getValue('core', 'aadApplicationId');
    this.tenantId = this.configService.getValue('core', 'aadTenantId');
    if (this.tenantId) {
      this.aadEnabled.next(true);
    }

    const authCode: string = this.storageService.getSessionStorageItem(AUTH_KEY);
    const bindCode: string = this.storageService.getSessionStorageItem(BIND_KEY);
    if (authCode) {
      this.authenticationCode = authCode;
    } else if (bindCode) {
      this.externalBindCode = bindCode;
      this.sessionService.getCurrentUser().then(this.bindAADTenant.bind(this));
    }

    myTenantApi.setApiDomain(configService.getValue('core', 'ApiDirectory'));
    myTenantApi.setHttpProvider(httpWrapper);

  }

  public setTenantId (tenantId: string): void {
    this.tenantId = tenantId;
    if (tenantId) {
      this.aadEnabled.next(true);
    }
  }

  public setApplicationId (applicationId: string): void {
    this.applicationId = applicationId;
  }

  public isAvailable (): Observable<boolean> {
    return this.aadEnabled.asObservable();
  }

  public location (): string {
    const state: string = this.generateState();
    const params: Array<string> = this.signinParams.concat(this.getUniversalParams(), [
      `state=${ state }`
    ]);

    if (this.tenantId) {
      this.storageService.setSessionStorageItem(STATE_KEY, state);
      return `${ this.authoriseUri() }?` + params.join('&');
    }
    return undefined;
  }

  public hasAuthenticationCode (): boolean {
    return !!this.authenticationCode;
  }

  public getAuthenticationCode (): string {
    return this.authenticationCode;
  }

  public authoriseUri (): string {
    if (this.tenantId === undefined) {
      return `https://${ this.host }/common/oauth2/authorize`;
    } else {
      return `https://${ this.host }/${ this.tenantId }/oauth2/authorize`;
    }
  }

  public redirectUri (): string {    
    return location.protocol + '//' + location.host + location.pathname;
  }

  public authorisation (): string {
    this.storageService.setSessionStorageItem('aadDeeplink', (<any>window).location.hash);
    const params: Array<string> = this.authorisationParams.concat(this.getUniversalParams());
    return `${ this.authoriseUri() }?` + params.join('&');
  }

  public unbindAADTenant (): Observable<boolean> {
    let subject: Subject<boolean> = new Subject<boolean>();
    this.myTenantApi.MyTenantServiceUnbindFromExternalIdentityProvider(
      {
        IdentityProvider: AAD_IDENTITY_PROVIDER
      }
    ).subscribe(successResponse => {
      this.unbindSuccess(successResponse);
      subject.next(true);
    }, failureResponse => {
      this.aadEnabled.next(true);
      subject.error(false);
    });
    return subject.asObservable();
  }

  private unbindSuccess (response: any): void {
    this.tenantId = undefined;
    this.aadEnabled.next(false);
    (<any>window).GDConfig.config['AAD.TenantId'] = undefined;
    (<any>window).GDConfig.config['IdentityProvider'] = GLOODOO_IDENTITY_PROVIDER;
  }

  private getUniversalParams (): Array<string> {
    return [
      `client_id=${ this.applicationId }`,
      `redirect_uri=${ encodeURIComponent(this.redirectUri()) }`,
      'response_type=code'
    ];
  }

  private generateState (): string {
    const str: string = [
      `${ Math.floor(window.crypto.getRandomValues(new Int32Array(1))[0] * 2001) }`,
      this.tenantId,
      `${ Date.now() }`
    ].join('');

    return this.hashFnv32a(str);
  }

  private bindAADTenant(): void {
    const payload: IBindToExternalIdentityProviderRequest = {
      IdentityProvider: AAD_IDENTITY_PROVIDER,
      AuthCode: this.externalBindCode,
      RedirectUri: this.redirectUri()
    }
    this.myTenantApi.MyTenantServiceBindToExternalIdentityProvider(payload)
    .subscribe(this.bindSuccess.bind(this), this.bindError.bind(this));
  }

  private bindSuccess (response: IIdentityTenant): void {
    const config = response.Claims;
    if (config) {
      (<any>window).GDConfig.config = config;
      if (config['AAD.TenantId']) {
        this.configService.setValue('core', 'aadTenantId', config['AAD.TenantId']);
        this.tenantId = config['AAD.TenantId'];
        this.aadEnabled.next(true);
      }

      if (config['AAD.ApplicationId']) {
        this.configService.setValue('core', 'aadApplicationId', config['AAD.ApplicationId']);
      }

      if (config['IdentityProvider']) {
        this.configService.setValue('core', 'identityProvider', config['IdentityProvider']);
      }
    }
    this.dialogService.showDialog('You can now sign in with your Microsoft account', 'OK', undefined, 'Success');
    this.storageService.removeSessionStorageItem(BIND_KEY);
  }

  private bindError (): void {
    this.dialogService.showDialog('Unfortunately something went wrong setting up Microsoft accounts. Try again later and if the problem persists, please contact the gloodoo team for assistance', 'OK', undefined, 'Failure');
    this.storageService.removeSessionStorageItem(BIND_KEY);
  }

  /**
   * Calculate a 32 bit FNV-1a hash
   * Found here: https://gist.github.com/vaiorabbit/5657561
   * Ref.: http://isthe.com/chongo/tech/comp/fnv/
   */
  private hashFnv32a (str: string, seed?: number): string {
    / *jshint bitwise:false * /
    let i: number;
    let l: number;
    let hval: number = !seed ? 0x811c9dc5 : seed;

    for (i = 0, l = str.length; i < l; i = i + 1) {
      hval ^= str.charCodeAt(i);
      hval += (hval << 1) + (hval << 4) + (hval << 7) + (hval << 8) + (hval << 24);
    }
    // Convert to 8 digit hex string
    return ('0000000' + (hval >>> 0).toString(16)).substr(-8);
  }
}
