import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  AbstractControl,
  FormGroup,
  FormControl,
  FormBuilder,
  Validators,
  ValidatorFn
} from '@angular/forms';
import { KeyboardService, CapsLockErrorStateMatcher, getActiveElementName } from '../../../core/services/keyboard.service';
import { AuthService } from '../../services/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { ErrorStateMatcher } from '@angular/material/core';
import { DirectoryApiClientService, IOauthTokenResponse, IAuthPasswordValidate } from '../../../../api/directory/index';
import { StorageService } from '../../../core/services/storage.service';

// common password library
declare var NBP: any;

@Component({
  selector: 'reset-password-component',
  styleUrls: ['reset-password.component.scss'],
  host: {'class': 'reset-password'},
  template: `
    <two-tone-container [messages]="twoToneMessages">
      <div *ngIf="isSubmitting || isInitialising" class="reset-password__loading">
        <mat-progress-spinner
          class="forgotten-password__loading__spinner"
          color="primary"
          diameter="50"
          stroke="3"
          mode="indeterminate">
        </mat-progress-spinner>
      </div>

      <div *ngIf="!isTokenValid && !isInitialising" class="reset-password__details__error">
        <h1>{{apiErrorTitle}}</h1>
        <br/>
        <div *ngFor="let line of apiErrorMessage">
          <p>{{line}}</p><br/>
        </div>
        <br/>
        <div>
          <a
            *ngIf="apiErrorButtonVisible"
            href="#"
            routerLink="/login"
            mat-raised-button
            class="reset-password__details__button reset-password__details__button__primary">
            {{ apiErrorButtonText }}
          </a>
        </div>
      </div>

      <div *ngIf="!isSubmitting && isTokenValid && !isInitialising" class="reset-password__details">
        <div *ngIf="!isComplete && !isError">
          <form [formGroup]="passwordFormGroup">
            <table class="reset-password__table">
              <tr>
                <td colspan="2" class="reset-password__details__title">
                  <p [innerHTML]="'core.resetPassword.newPassword.title' | translate: firstName"></p>
                  <p>{{ 'core.resetPassword.newPassword.message' | translate }}</p>
                </td>
              </tr>
              <tr>
                <td class="reset-password__details__icon-column">
                  <img src="assets/icons/password.svg" className="reset-password__details__icon {{ passwordError()? 'reset-password__details__error' : '' }}" #passwordIcon />
                </td>
                <td>
                  <mat-form-field class="reset-password__details__mat-field-full-width">
                    <mat-label>{{ 'core.login.password' | translate }}</mat-label>
                    <input
                      id="password"
                      matInput
                      class="reset-password__details__input"
                      type="{{ (!showPassword || isSubmitting)? 'password' : 'text' }}"
                      name="password"
                      formControlName="password"
                      [disabled]="isSubmitting"
                      [errorStateMatcher]="capsLockMatcher"
                      #password>
                    <mat-hint *ngIf="((keyboardService.capsLockState | async) && passwordFocused())">
                      {{ 'core.global.capsLockIsOn' | translate }}
                    </mat-hint>
                    <mat-error *ngIf="errorMessage">
                      <span *ngIf="(keyboardService.capsLockState | async)">{{ 'core.global.capsLockIsOn' | translate }}</span> {{ errorMessage }}
                    </mat-error>
                  </mat-form-field>
                </td>
              </tr>
              <tr
                *ngIf="!isSubmitting"
                (click)="changePasswordType()"
                class="login-view__login-panel__show-password">
                <td class="login-view__login-panel__icon-column">
                  <img
                    src="assets/icons/show-password-{{ showPassword? 'in' : '' }}visible.svg"
                    class="login-view__login-panel__icon"/>
                </td>
                <td>
                  <button type="button">
                    <span *ngIf="showPassword">
                      {{ 'core.login.hidePassword' | translate }}
                    </span>
                    <span *ngIf="!showPassword">
                      {{ 'core.login.showPassword' | translate }}
                    </span>
                  </button>
                </td>
              </tr>
              <tr *ngIf="!isSubmitting">
                <td colspan="2" class="reset-password__details__requirements">
                  {{ passwordPolicy.Message }}
                </td>
              </tr>
              <tr *ngIf="!isSubmitting">
                <td colspan="2">
                  <button
                    mat-raised-button
                    [disabled]="!completeButtonEnabled()"
                    (click)="completeReset($event)"
                    class="reset-password__details__button reset-password__details__button__primary">
                    {{ 'core.resetPassword.newPassword.complete' | translate }}
                  </button>
                </td>
              </tr>
              <tr *ngIf="isSubmitting">
                <td colspan="2">
                  <mat-progress-spinner
                    class="reset-password__details__spinner"
                    color="primary"
                    diameter="50"
                    stroke="3"
                    mode="indeterminate">
                  </mat-progress-spinner>
                </td>
              </tr>
            </table>
          </form>
        </div>

        <div class="reset-password__details__complete" *ngIf="isComplete">
          <table>
            <tr>
              <td>
                {{ 'core.resetPassword.complete.prompt' | translate }}
              </td>
            </tr>
            <tr>
              <td>
                <button
                  #continueToLoginButton
                  id="continueToLoginButton"
                  name="continueToLoginButton"
                  mat-raised-button
                  (click)="continueToLogin($event)"
                  class="reset-password__details__button reset-password__details__button__primary">
                  {{ 'core.resetPassword.complete.continue' | translate }}
                </button>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </two-tone-container>
  `
})
export class ResetPasswordComponent implements OnInit {
  private resetToken: string;
  resetFailed: boolean = false;
  errorMessage: string;
  isInitialising: boolean = true;
  isTokenValid: boolean;
  isSubmitting: boolean = false;
  showPassword: boolean = false;
  capsLockMatcher: ErrorStateMatcher;
  private confirmFormGroup: FormGroup;
  passwordFormGroup: FormGroup;
  private isConfirmed: boolean = false;
  isComplete: boolean = false;
  isError: boolean = false;
  username: string;
  firstName: any = { value : ''};
  private tenantId: string;
  passwordPolicy : any;
  twoToneMessages: Array<string>;

  apiErrorTitle: string;
  apiErrorMessage: string;
  apiErrorButtonText: string;
  apiErrorButtonVisible: boolean = false;

  private passwordMinLength: number = 0;
  keyboardService: KeyboardService;
  constructor (
    private authService: AuthService,
    private fb: FormBuilder,
    keyboardService: KeyboardService,
    private route: ActivatedRoute,
    private router: Router,
    private translate: TranslateService
  ) {
    this.keyboardService = keyboardService;
    this.capsLockMatcher = new CapsLockErrorStateMatcher(this.keyboardService, 'password');
    this.tenantId = authService.getTenantId();

    this.confirmFormGroup = this.fb.group({
      username: ['', Validators.required]
    });

    this.passwordFormGroup = this.fb.group({
      'password': ['', Validators.compose([
        Validators.minLength(this.passwordMinLength),
        this.passwordValidator(),
        Validators.required
      ])],
    });
    (<FormControl>this.passwordFormGroup.controls['password']).valueChanges.subscribe((value) => {
      this.errorMessage = '';
    });

    this.resetToken = sessionStorage.getItem('resettoken');
    this.authService.validateToken(this.resetToken).subscribe(
      (userDetails: IAuthPasswordValidate) => {
        this.firstName.value = userDetails.FirstName;
        this.username = userDetails.UserName;
        this.isInitialising = false;
        this.isTokenValid = true;
        sessionStorage.removeItem('resettoken');
        this.setFocus('password');
      },
      error => {
        this.handleApiError(error);
        this.isInitialising = false;
        this.isTokenValid = false;
        sessionStorage.removeItem('resettoken');
      }
    );
  }

  private handleApiError (error: Response) {
    switch (error.status) {
      case 403:
        this.translate.get('core.resetPassword.error.invalidToken').subscribe((res: any) => {
          this.apiErrorTitle = res.title;
          this.apiErrorMessage = res.message;
          this.apiErrorButtonText = res.buttonText;
          this.apiErrorButtonVisible = true;
        });
        break;
      default:
        this.translate.get('core.resetPassword.error.unknown').subscribe((res: any) => {
          this.apiErrorTitle = res.title;
          this.apiErrorMessage = res.message;
          this.apiErrorButtonText = "";
          this.apiErrorButtonVisible = false;
        });
        break;
    }
  }

  public ngOnInit (): void {
    this.authService.getPasswordPolicy(this.tenantId).subscribe(policy => {
      if(policy) {
      this.passwordPolicy = policy;
      this.passwordMinLength = policy.MinimumLength;
      }
    });
    this.translate.get('core.resetPassword.title.message').subscribe(message => {
      this.twoToneMessages = message;
    });
  }

  confirmValidator () {
    return this.isConfirmed? null : {
      validateConfirm: {
        valid: false
      }
    };
  }

  private passwordValidator (): ValidatorFn {
    return (control: AbstractControl): any => {
      return NBP.isCommonPassword(control.value) ? { validateConfirm: {value: control.value}} : null;
    };
  }

  confirmEmail () {
    this.isConfirmed = true;
    (<FormControl>this.confirmFormGroup.controls['username']).updateValueAndValidity();
  }

  completeReset (event: Event) {
    event.preventDefault();
    event.stopPropagation();
    const newPassword: string = (<FormControl>this.passwordFormGroup.controls['password']).value;

    if ( NBP.isCommonPassword(newPassword) )
    {
      if (newPassword.length < this.passwordMinLength){
        this.translate.get('core.resetPassword.newPassword.invalid').subscribe((res: string) => {
          this.errorMessage = res;
        });
      } else {
        this.translate.get('core.resetPassword.newPassword.tooCommon').subscribe((res: string) => {
          this.errorMessage = res;
        });
      }
      return;
    } else {
      if (newPassword.length < this.passwordMinLength){
        this.translate.get('core.resetPassword.newPassword.tooShort').subscribe((res: string) => {
          this.errorMessage = res;
        });
        return;
      }
    }
    this.isSubmitting = true;
    this.authService.resetPassword(newPassword, this.resetToken).subscribe(
      {next: data => {
        this.isSubmitting = false;
        this.isComplete = true;
        // this.setFocus("continueToLoginButton");
      }, error:() => {
        this.isSubmitting = false;
        this.resetFailed = true;
      }}
    );
  }

  private setFocus(target) {
    setTimeout(function waitTargetElem() {
      const targetElem = document.getElementById(target);
      if (document.body.contains(targetElem)) {
        targetElem.focus();
      } else {
        setTimeout(waitTargetElem, 100);
      }
    }, 100);
  }

  resetFormErrors () {
    this.resetFailed = false;
    if ((<FormControl>this.passwordFormGroup.controls['password']).value !== '') {
      (<FormControl>this.passwordFormGroup.controls['password']).setErrors(null);
    } else {
      (<FormControl>this.passwordFormGroup.controls['password']).setErrors(['invalid']);
    }
  }

  continueToLogin (event) {
    event.preventDefault();
    event.stopPropagation();
    sessionStorage.setItem('stored_username',this.username);
    sessionStorage.setItem('stored_password',(<FormControl>this.passwordFormGroup.controls['password']).value);
    this.router.navigate([ '../login' ], { relativeTo: this.route });
  }

  passwordError (): boolean {
    return !(<FormControl>this.passwordFormGroup.controls['password']).valid;
  }

  changePasswordType () {
    this.showPassword = !this.showPassword;
  }

  passwordFocused (): boolean {
    return getActiveElementName() === "password";
  }

  completeButtonEnabled(){
    return (<FormControl>this.passwordFormGroup.controls['password']).value != '';
  }
}
