import { Component, Input } from '@angular/core';
import { ConfigService } from '../../../core/index';
import { AuthService } from '../../services/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { ErrorStateMatcher } from '@angular/material/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {MatCheckbox} from '@angular/material/checkbox';
@Component({
  selector: 'two-tone-container',
  styleUrls: ['two-tone-holder.component.scss'],
  template: `
    <section class="two-tone-container__frame">
      <div *ngIf="backgroundStyle"
        class="login-background"
        [ngStyle]="backgroundStyle">
      </div>
      <div class="two-tone-container__holder">
        <mat-card [ngClass]="'two-tone-container__card'">
          <div class="two-tone-container__welcome-panel">
            <div class="two-tone-container__welcome-panel__icon">
              <img src='assets/brand/logo-simple.svg' />             
            </div>
            <div class="two-tone-container__welcome-panel__welcome-message">
              <p *ngIf="messages[0]">{{ messages[0] | translate }}</p>
              <p
                *ngIf="messages[1]"
                class="two-tone-container__welcome-panel__welcome-message__domain">
                {{ messages[1] }}
              </p>
              <p *ngIf="messages[2]">{{ messages[2] }}</p>
            </div>
          </div>
          <ng-content></ng-content>
        </mat-card>
      </div>
    </section>
  `
})
export class TwoToneHolderComponent {
  @Input() messages: Array<string> = [];

  private tenantId: string;
  private tenantDomain: string;
  private domainName: string;
  private logoPath: string;
  private DEFAULT_LOGO: string = 'assets/brand/logo-simple.svg';
  backgroundStyle: Object;

  constructor (
    private authService: AuthService,
    private configService: ConfigService,
    private translate: TranslateService
  ) {
    this.tenantId = authService.getTenantId();
    this.logoPath = this.getLogo();
    this.backgroundStyle = this.getBackgroundStyle();

    const dotIndex = location.hostname.indexOf('.');
    this.tenantDomain = location.hostname.substring(0, dotIndex);
    this.domainName = location.hostname.substring(dotIndex, location.hostname.length);

    // default welcome messages
    this.translate.get('core.login.welcome').subscribe(msg => {
      this.messages = [ msg, this.tenantDomain, this.domainName ]
    });
  }

  private getLogo (): string {
    const tenantLogo = this.configService.getValue('core', 'branding').loginScreenLogo;
    return tenantLogo ? tenantLogo : this.DEFAULT_LOGO;
  }

  private getBackgroundStyle(): Object {
    const branding = this.configService.getValue('core', 'branding');
    const image = branding.loginScreenBackground;
    const color = branding.loginScreenBackgroundColor;
    const position = branding.loginScreenBackgroundPosition;
    let style = {};
    if (image) {
      style['backgroundImage'] = `url(${image})`;
      if (position) {
        style['backgroundPosition'] = position;
      }
      if (color) {
        style['backgroundColor'] = color;
      }
      return style;
    }
    if (color) {
      style['backgroundColor'] = color;
      return style;
    }
    return undefined;
  }
}
