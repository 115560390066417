declare var System: any;

import { Injectable } from '@angular/core';
import * as _ from 'lodash';
@Injectable()
export class LazyInjector {
  public injectJSDependencies (dependencies: any): void {
    System.config(dependencies);
  }

  public injectStyleDependencies (dependencies: Array<string>): void {
    const headElement = document.querySelector('head');

    _.each(dependencies, function (src) {
      const styleElement = document.createElement('link');
      styleElement.rel = 'stylesheet';
      styleElement.href = src;
      headElement.appendChild(styleElement);
    });
  }
}
