import { Component } from '@angular/core';

@Component({
  styleUrls: ['not-auth.component.scss'],
  template: `
    <mat-card class="not-auth__card">
      <div class="not-auth">
        <div class="not-auth__grphic-column">
          <img class="not-auth__graphic" role="presentation" src="/assets/errors/sad.svg"/>
        </div>
        <div class="not-auth__details-column">
          <h1>Oops!</h1>
          <p>You are not authorized to access this page.</p>
        </div>
      </div>
    </mat-card>
  `
})
export class NotAuthComponent {}
