


export class AuthLoginResponse implements IAuthLoginResponse {
  accessToken: string;
  tokenType: string;
  expiresIn: number;
  refreshToken: string;
  clientId: string;
  userId: string;
  userPermissions: string[];
  userRoles: string[];
  issued: Date;
  expires: Date;

  constructor(response: any) {
    this.accessToken = response.access_token;
    this.tokenType = response.token_type;
    this.expiresIn = response.expires_in;
    this.refreshToken = response.refresh_token;
    this.clientId = response.client_id;
    this.userId = response.user_id;
    this.userPermissions = response.user_permissions !== undefined ? response.user_permissions.split(',') : '';
    this.userRoles = response.user_roles !== undefined ? response.user_roles.split(',') : '';
    this.issued = new Date(response['.issued']);
    this.expires = new Date(response['.expires']);
  }
}

export interface IAuthLoginResponse {
  accessToken: string;
  tokenType: string;
  expiresIn: number;
  refreshToken: string;
  clientId: string;
  userId: string;
  userPermissions: string[];
  userRoles: string[];
  issued: Date;
  expires: Date;
}
