import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  AbstractControl,
  FormGroup,
  FormControl,
  FormBuilder,
  Validators,
  ValidatorFn
} from '@angular/forms';
import { KeyboardService, CapsLockErrorStateMatcher, getActiveElementName } from '../../../core/services/keyboard.service';
import { AuthService } from '../../services/auth.service';
import { TranslateService } from '@ngx-translate/core';
import {  MatStepper } from '@angular/material/stepper';
import { ErrorStateMatcher } from '@angular/material/core';
import { DirectoryApiClientService, IOauthTokenResponse } from '../../../../api/directory/index';
import { StorageService } from '../../../core/services/storage.service';

// common password library
declare var NBP: any;

@Component({
  selector: 'first-time-component',
  styleUrls: ['first-time.component.scss'],
   host: {'class': 'first-time'},
  template: `
    <two-tone-container>
      <div class="first-time__details-panel">
        <mat-progress-spinner
          *ngIf="isInitialising"
          class="first-time__details-panel__spinner"
          color="primary"
          diameter="50"
          stroke="3"
          mode="indeterminate">
        </mat-progress-spinner>

        <div *ngIf="!isInitialising && !isTokenValid" class="first-time__details-panel__error">
          <h1>{{apiErrorTitle}}</h1>
          <br/>
          <div *ngFor="let line of apiErrorMessage">
            <p>{{line}}</p><br/>
          </div>
          <br/>
          <div>
            <a
              *ngIf="apiErrorButtonVisible"
              href="#"
              routerLink="/login"
              mat-raised-button
              class="first-time__details-panel__button first-time__details-panel__button__primary">
              {{ apiErrorButtonText }}
            </a>
          </div>
        </div>

        <mat-horizontal-stepper
          linear="true"
          #stepper="matHorizontalStepper"
          *ngIf="!isInitialising && isTokenValid && !isComplete"
          (selectionChange)="selectedSteppedChanged($event)"
          class="first-time__details-panel__stepper">

          <ng-template matStepperIcon="edit">
            <mat-icon>done</mat-icon>
          </ng-template>

          <mat-step
            [stepControl]="confirmFormGroup"
            label="{{ 'core.firstTime.confirmEmail.stepName' | translate }}"
            editable="false">
            <form [formGroup]="confirmFormGroup">
              <table class="first-time__table">
                <tr>
                  <td class="first-time__details-panel__icon-column">
                    <img
                      src="assets/icons/user.svg"
                      class="first-time__details-panel__icon"
                      #userIcon/>
                  </td>
                  <td>
                    <mat-form-field class="first-time__details-panel__mat-field-full-width">
                      <input
                        matInput
                        autofocus
                        class="first-time__details-panel__input"
                        placeholder="{{ 'core.login.email' | translate }}"
                        type="email"
                        name="username"
                        readonly="true"
                        formControlName="username"
                        autocomplete="off"/>
                    </mat-form-field>
                  </td>
                </tr>
                <tr>
                  <td colspan="2" class="first-time__details-panel__requirements">
                    {{ 'core.firstTime.confirmEmail.prompt' | translate }}
                  </td>
                </tr>
                <tr>
                  <td colspan="2">
                    <button
                      matStepperNext
                      mat-raised-button
                      (click)="confirmEmail()"
                      [disabled]=""
                      class="first-time__details-panel__button first-time__details-panel__button__primary">
                      {{ 'core.firstTime.confirmEmail.confirm' | translate }}
                    </button>
                  </td>
                </tr>
              </table>
            </form>
          </mat-step>

          <mat-step
            [stepControl]="passwordFormGroup"
            label="{{ 'core.firstTime.createPassword.stepName' | translate }}">
            <form [formGroup]="passwordFormGroup">
              <table class="first-time__table">
                <tr>
                  <td class="first-time__details-panel__icon-column">
                    <img src="assets/icons/password.svg" class="first-time__details-panel__icon {{ passwordError()? 'first-time__details-panel__error' : '' }}" #passwordIcon />
                  </td>
                  <td>
                    <mat-form-field class="first-time__details-panel__mat-field-full-width">
                      <input
                        id="password"
                        matInput
                        class="first-time__details-panel__input"
                        placeholder="{{ 'core.login.password' | translate }}"
                        type="{{ (!showPassword || isSubmitting)? 'password' : 'text' }}"
                        name="password"
                        formControlName="password"
                        [disabled]="isSubmitting"
                        [errorStateMatcher]="capsLockMatcher"
                        #password>
                      <mat-hint *ngIf="((keyboardService.capsLockState | async) && passwordFocused())">
                        {{ 'core.global.capsLockIsOn' | translate }}
                      </mat-hint>
                      <mat-error *ngIf="errorMessage">
                        <span *ngIf="(keyboardService.capsLockState | async)">{{ 'core.global.capsLockIsOn' | translate }}</span> {{ errorMessage }}
                      </mat-error>
                    </mat-form-field>
                  </td>
                </tr>
                <tr
                  *ngIf="!isSubmitting"
                  (click)="changePasswordType()"
                  class="login-view__login-panel__show-password">
                  <td class="login-view__login-panel__icon-column">
                    <img
                      src="assets/icons/show-password-{{ showPassword? 'in' : '' }}visible.svg"
                      class="login-view__login-panel__icon"/>
                  </td>
                  <td>
                    <button type="button">
                      <span *ngIf="showPassword">
                        {{ 'core.login.hidePassword' | translate }}
                      </span>
                      <span *ngIf="!showPassword">
                        {{ 'core.login.showPassword' | translate }}
                      </span>
                    </button>
                  </td>
                </tr>
                <tr *ngIf="!isSubmitting">
                  <td colspan="2" class="first-time__details-panel__requirements">
                    {{ passwordPolicy.Message }}
                  </td>
                </tr>
                <tr *ngIf="!isSubmitting">
                  <td colspan="2">
                    <button
                      mat-raised-button
                      matStepperNext
                      [disabled]="!completeButtonEnabled()"
                      (click)="completeReset()"
                      class="first-time__details-panel__button first-time__details-panel__button__primary">
                      {{ 'core.firstTime.createPassword.complete' | translate }}
                    </button>
                  </td>
                </tr>
                <tr *ngIf="isSubmitting">
                  <td colspan="2">
                    <mat-progress-spinner
                      class="first-time__details-panel__spinner"
                      color="primary"
                      diameter="50"
                      stroke="3"
                      mode="indeterminate">
                    </mat-progress-spinner>
                  </td>
                </tr>
              </table>
            </form>
          </mat-step>
        </mat-horizontal-stepper>

        <div class="first-time__details-panel__complete" *ngIf="isComplete">
          <table>
            <tr>
              <td>
                {{ 'core.firstTime.complete.prompt' | translate }}
              </td>
            </tr>
            <tr>
              <td>
                <button
                  id="continueToLoginButton"
                  name="continueToLoginButton"
                  #continueToLoginButton
                  mat-raised-button
                  (click)="continueToLogin()"
                  class="first-time__details-panel__button first-time__details-panel__button__primary">
                  {{ 'core.firstTime.complete.continue' | translate }}
                </button>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </two-tone-container>
  `
})
export class FirstTimeComponent implements OnInit {
  resetToken: string;
  resetFailed: boolean = false;
  errorMessage: string;
  isInitialising: boolean = true;
  isSubmitting: boolean;
  isTokenValid: boolean;
  showPassword = false;
  capsLockMatcher: ErrorStateMatcher;
  confirmFormGroup: FormGroup;
  passwordFormGroup: FormGroup;
  isConfirmed: boolean = false;
  isComplete: boolean = false;
  tenantId: string;
  passwordPolicy : any;

  apiErrorTitle: string;
  apiErrorMessage: string;
  apiErrorButtonText: string;
  apiErrorButtonVisible: boolean = false;

  private passwordMinLength: number = 0;

  //needs to be discussed
  @ViewChild('stepper', {static: false}) private stepper: MatStepper;
  keyboardService: KeyboardService;
  constructor (
    private authService: AuthService,
    private fb: FormBuilder,
    keyboardService: KeyboardService,
    private route: ActivatedRoute,
    private router: Router,
    private translate: TranslateService
  ) {
    this.keyboardService = keyboardService;
    this.tenantId = authService.getTenantId();
    this.capsLockMatcher = new CapsLockErrorStateMatcher(keyboardService, 'password');

    this.isInitialising = true;
    this.isTokenValid = false;

    this.confirmFormGroup = this.fb.group({
      username: ['', Validators.required]
    });

    this.passwordFormGroup = this.fb.group({
      'password': ['', Validators.compose([
        Validators.minLength(this.passwordMinLength),
        this.passwordValidator(),
        Validators.required
      ])],
    });
    (<FormControl>this.passwordFormGroup.controls['password']).valueChanges.subscribe((value) => {
      this.errorMessage = '';
    });

    this.resetToken = sessionStorage.getItem('firsttimetoken');
    this.authService.validateToken(this.resetToken).subscribe(
      (userDetails) => {
        if (userDetails.UserName) {
          this.isTokenValid = true;
          (<FormControl>this.confirmFormGroup.controls['username']).setValue( userDetails.UserName );
        }

        this.isInitialising = false;
        sessionStorage.removeItem('firsttimetoken');
      },
      (error) => {
        this.handleApiError(error);
        this.isInitialising = false;
        sessionStorage.removeItem('firsttimetoken');
      }
    );
  }

  private handleApiError (error: Response) {
    switch (error.status) {
      case 403:
        this.translate.get('core.firstTime.errors.invalidToken').subscribe((res: any) => {
          this.apiErrorTitle = res.title;
          this.apiErrorMessage = res.message;
          this.apiErrorButtonText = res.buttonText;
          this.apiErrorButtonVisible = true;
        });
        break;
      default:
        this.translate.get('core.firstTime.errors.unknown').subscribe((res: any) => {
          this.apiErrorTitle = res.title;
          this.apiErrorMessage = res.message;
          this.apiErrorButtonText = "";
          this.apiErrorButtonVisible = false;
        });
        break;
    }
  }

  public ngOnInit (): void {
    this.authService.getPasswordPolicy(this.tenantId).subscribe(policy => {
      if(policy) {
      this.passwordPolicy = policy;     
      this.passwordMinLength = policy.MinimumLength;
      }
    });  
  }

  confirmValidator () {
    return this.isConfirmed? null : {
      validateConfirm: {
        valid: false
      }
    };
  }

  private passwordValidator (): ValidatorFn {
    return (control: AbstractControl): any => {
      return NBP.isCommonPassword(control.value) ? { validateConfirm: {value: control.value}} : null;
    };
  }

  confirmEmail () {
    this.isConfirmed = true;
    (<FormControl>this.confirmFormGroup.controls['username']).updateValueAndValidity();
    this.stepper.next();
  }

  completeReset () {
    const newPassword: string = (<FormControl>this.passwordFormGroup.controls['password']).value;

    if ( NBP.isCommonPassword(newPassword) )
    {
      if (newPassword.length < this.passwordMinLength){
        this.translate.get('core.firstTime.createPassword.invalid').subscribe((res: string) => {
          this.errorMessage = res;
        });
      } else {
        this.translate.get('core.firstTime.createPassword.tooCommon').subscribe((res: string) => {
          this.errorMessage = res;
        });
      }
      return;
    } else {
      if (newPassword.length < this.passwordMinLength){
        this.translate.get('core.firstTime.createPassword.tooShort').subscribe((res: string) => {
          this.errorMessage = res;
        });
        return;
      }
    }

    this.isSubmitting = true;
    this.authService.resetPassword(newPassword, this.resetToken).subscribe(
      data => {
        this.isSubmitting = false;
        this.isComplete = true;
        // this.setFocus("continueToLoginButton");
      }, (error) => {
        this.isSubmitting = false;
        this.resetFailed = true;
        this.translate.get('core.firstTime.createPassword.invalid').subscribe((res: string) => {
          this.errorMessage = res;
        });
        (<FormControl>this.passwordFormGroup.controls['password']).setErrors(['invalid']);
      }
    );
  }

  private setFocus(target) {
    setTimeout(function waitTargetElem() {
      const targetElem = document.getElementById(target);
      if (document.body.contains(targetElem)) {
        targetElem.focus();
      } else {
        setTimeout(waitTargetElem, 100);
      }
    }, 100);
  }

  resetFormErrors () {
    this.resetFailed = false;
    if ((<FormControl>this.passwordFormGroup.controls['password']).value !== '') {
      (<FormControl>this.passwordFormGroup.controls['password']).setErrors(null);
    } else {
      (<FormControl>this.passwordFormGroup.controls['password']).setErrors(['invalid']);
    }
  }

  continueToLogin () {
    sessionStorage.setItem('stored_username',(<FormControl>this.confirmFormGroup.controls['username']).value);
    sessionStorage.setItem('stored_password',(<FormControl>this.passwordFormGroup.controls['password']).value);
    this.router.navigate([ '../login' ], { relativeTo: this.route });
  }

  passwordError (): boolean {
    return !(<FormControl>this.passwordFormGroup.controls['password']).valid;
  }

  changePasswordType () {
    this.showPassword = !this.showPassword;
  }

  passwordFocused (): boolean {
    return getActiveElementName() === "password";
  }

  completeButtonEnabled(){
    return (<FormControl>this.passwordFormGroup.controls['password']).value != '';
  }

  selectedSteppedChanged(event: any){
    if(event.selectedIndex == 1) {
      setTimeout( () => { this.setFocus("password"); }, 250);
    }
  }
}
