import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { ConfigService } from '../services/config.service';

@Injectable({
  providedIn: 'root'
})
@Pipe({
  name: 'GDSource'
})
export class GDSourcePipe implements PipeTransform {
  constructor (private configService: ConfigService) {}

  public transform (value: string, appId: string): Array<string> {
    if (!value) return [ '' ];
    if (this.configService.getValue(appId, 'portalMode')) {
      const appPath: string = this.configService.getValue(appId, 'appPath');
      if (appPath) {
        return [ `${ appPath }/${ value }` ];
      }
    }
    return [ value ];
  }
}
