import { ChangeDetectorRef, Component, Input, Inject } from '@angular/core';
import { DomSanitizer, SafeResourceUrl, SafeHtml } from '@angular/platform-browser';
import { NgModel, FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { ConfigService } from '../../../core/index';
import { DirectoryApiClientService } from '../../../../api/directory/index';
import { TranslateService } from '@ngx-translate/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {MatCheckbox} from '@angular/material/checkbox';

@Component({
  selector: 'tos-modal',
  styleUrls: ['tos-modal.component.scss'],
  template:`
    <div class="tos-modal__container">
      <h1 mat-dialog-title>{{ 'core.tos.title' | translate }}</h1>
      <mat-spinner *ngIf="!tosBody && !fetchErrorMessage" class="tos-modal__loading"></mat-spinner>
      <div class="tos-modal__body" [innerHTML]="tosBody"></div>
      <span *ngIf="fetchErrorMessage" noticeType="error">
        {{ fetchErrorMessage }}
      </span>
      <span *ngIf="acceptErrorMessage" noticeType="error">
        {{ acceptErrorMessage }}
      </span>
      <mat-checkbox
        class="tos-modal__checkbox"
        aria-label="{{ 'core.tos.checkbox.accept' | translate }}"
        [(ngModel)]="isAccepted"
        [disabled]="isError || isSubmitting"
        #rememberMe>
        {{ 'core.tos.checkbox.accept' | translate }}
      </mat-checkbox>
      <button
        mat-button
        class="tos-modal__button"
        [disabled]="!isAccepted"
        (click)="onAcceptTerms()">
        {{ 'core.tos.button.confirm' | translate }}
      </button>
    </div>
  `
})
export class TermsOfServiceModalComponent {
  public blockClose: boolean = true;

  acceptErrorMessage: string;
  fetchErrorMessage: string;
  isAccepted: boolean = false;
  isError: boolean = true;
  isSubmitting: boolean = false;
  tosBody: SafeHtml;

  constructor (
    private api: DirectoryApiClientService,
    private changeDetector: ChangeDetectorRef,
    private sanitizer: DomSanitizer,
    private configService: ConfigService,
    private fb: FormBuilder,
    private dialog: MatDialogRef<TermsOfServiceModalComponent>,
    private translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  public ngOnInit (): void {
    this.api.AuthServiceGetTermsOfService(this.data.errorToken)
    .subscribe(result => {
      this.tosBody = this.sanitizer.bypassSecurityTrustHtml(result);
      this.isError = false;
    },
    error => {
      this.translate.get('core.tos.error.fetch').subscribe((res: string) => {
        this.fetchErrorMessage = res;
      });
    });
  }

  onAcceptTerms (): void {
    this.isSubmitting = true;
    this.acceptErrorMessage = undefined;
    this.api.AuthServiceAcceptTermsOfService(this.data.errorToken)
    .subscribe(result => {
      this.dialog.close();
    },
    error => {
      let status = error.status;
      this.isSubmitting = false;
      if (!status && error.HttpCode) {
        status = error.HttpCode;
      }

      if (error.message) {
        this.acceptErrorMessage = error.message;
      } else {
        switch(status) {
          case 500:
          case 501:
          case 502:
          case 503:
            this.translate.get('core.tos.error.accept.server').subscribe((res: string) => {
              this.acceptErrorMessage = res;
            });
            break;
          default:
            this.translate.get('core.tos.error.accept.common').subscribe((res: string) => {
              this.acceptErrorMessage = res;
            });
            break;
        }
      }
    });
  }
}
