import { Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject, throwError } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { AnalyticsService } from '../../core/services/analytics.service';
import { ConfigService } from '../../core/services/config.service';
import { StorageService } from '../../core/services/storage.service';
import { AuthLoginResponse, IAuthLoginResponse } from '../models/auth.login.response';

import _ from 'lodash';

import {
  DirectoryApiClientService,
  IOauthTokenResponse,
  IForgottenPasswordRequest
} from '../../../api/directory/index';

const REFRESH_TOKEN_KEY: string = 'refreshToken';
const CLIENT_ID: string = 'adapt_portal';
const REDIRECT_ID: string = 'redicectUri';
const REAUTH_OFFSET: number = 30000;

let AADLogin: boolean;
let accessToken: string;
let reAuthTimer: any;
let reAuthSubject: Subject<IAuthLoginResponse>;
let storedUsername: string;
let userId: string;
let userPermissions: Array<string>;

export enum AuthMode {
  Default,
  Bypass,
  Mobile
}

@Injectable()
export class AuthService {
  private tenantId: string = this.parseTenantId();
  private rememberMe: boolean;
  public redirectUrl: string = 'app';
  public authMode: AuthMode = AuthMode.Default;

  constructor (
    private http: HttpClient,
    private injector: Injector,
    private analyticsService: AnalyticsService,
    private directoryApi: DirectoryApiClientService,
    private configService: ConfigService,
    private storageService: StorageService,
  ) {
    directoryApi.setApiDomain(configService.getValue('core', 'ApiDirectory'));

    this.authMode = AuthMode[this.configService.getValue('core', 'authMode') as string];

    if (this.authMode !== AuthMode.Bypass && (<any>window).GloodooMob) {
      this.authMode = AuthMode.Mobile;
    }

    this.redirectUrl = sessionStorage.getItem(REDIRECT_ID) || 'app';
  //  this.storageService.removeLocalStorageItem(REDIRECT_ID);
    (<any>window).addEventListener('online', this.onOnline.bind(this));
  }

  private parseTenantId (): string {
    const tid: string = _.get(window, 'GDConfig.tenant.id');

    return (!tid || tid == '' || tid.indexOf('tenantId') > -1) ? undefined : tid;
  }

  public getAccessToken (): string {
    return accessToken;
  }

  public getRefreshToken (): string {
    let storedRefreshToken: string;

    storedRefreshToken = this.storageService.getLocalStorageItem(REFRESH_TOKEN_KEY);
    // Since refreshToken was stored in localStorage, rememberMe must have been turned on on login - turn it on so on refresh the token is stored again
    if (storedRefreshToken) {
      this.rememberMe = true;
    } else {
      storedRefreshToken = this.storageService.getSessionStorageItem(REFRESH_TOKEN_KEY);
    }

    return storedRefreshToken;
  }

  public getTenantId (): string {
    return this.tenantId;
  }

  public setTenantId (tenantId: string): void {
    this.tenantId = tenantId;
  }

  public getUserId (): string {
    return userId;
  }

  // Deprecated
  public hasRole (role: string): boolean {
    return _.indexOf(userPermissions, role) > -1;
  }

  public hasPermission (permission: string): boolean {
    return _.indexOf(userPermissions, permission) > -1;
  }

  public getPermissions (): Array<string> {
    return userPermissions;
  }

  public isAADLogin (): boolean {
    return AADLogin;
  }

  public logIn ({ username, password }: { username: string, password: string }, remember?: boolean): Observable<IAuthLoginResponse> {
    const loginSubject: Subject<IAuthLoginResponse> = new Subject<IAuthLoginResponse>();
    const authPostPayload: any = {
      client_id: CLIENT_ID,
      grant_type: 'password',
      password: password,
      tenant_id: this.getTenantId(),
      username: username
    };

    this.rememberMe = remember;
    this.logInHandler(loginSubject, authPostPayload, this.onLogInSuccess, this.onLogInFail);
    return loginSubject as Observable<IAuthLoginResponse>;
  }

  public logInAd ({ code, redirectUri }: { code: string, redirectUri: string }): Observable<IAuthLoginResponse> {
    const loginAdSubject: Subject<IAuthLoginResponse> = new Subject<IAuthLoginResponse>();
    const adPostToken: any = {
      authorization_code: code,
      client_id: CLIENT_ID,
      grant_type: 'authorization_code',
      authentication_source: 'AzureAD',
      redirect_uri: redirectUri,
      tenant_id: this.getTenantId()
    };

    this.rememberMe = false;
    this.logInHandler(loginAdSubject, adPostToken, this.onLogInSuccess, this.onLogInFail);
    return loginAdSubject as Observable<IAuthLoginResponse>;
  }

  public reAuth (): Observable<IAuthLoginResponse> {
    let reAuthPostToken: any;

    // If a re-auth request is in-flight, return the existing subject
    if (reAuthSubject && !reAuthSubject.isStopped) {
      return reAuthSubject;
    }

    // Create a new re-auth request
    reAuthSubject = new Subject<IAuthLoginResponse>();

    if (this.authMode === AuthMode.Mobile) {
      setTimeout(() => {
        reAuthSubject.next(JSON.parse((<any>window).GloodooMob.GetAuthToken()));
        reAuthSubject.complete();
      });
      return reAuthSubject as Observable<IAuthLoginResponse>;
    }

    reAuthPostToken = {
      client_id: CLIENT_ID,
      grant_type: 'refresh_token',
      refresh_token: this.getRefreshToken(),
      tenant_id: this.getTenantId()
    };

    this.logInHandler(reAuthSubject, reAuthPostToken, this.onReAuthSuccess, this.onReAuthFail);
    return reAuthSubject as Observable<IAuthLoginResponse>;
  }

  public validateToken (token: string ): Observable<any> {
    return this.directoryApi.AuthServiceGetPasswordValidateToken(token);
  }

  public getPasswordPolicy (tenantId : string) : Observable<any>{
    return this.directoryApi.AuthServiceGetPasswordPolicy(tenantId);
  }
  
  public forgottenPassword (email: string): Observable<any> {
    const request: IForgottenPasswordRequest = {
      Email: email,
      Tenant: this.tenantId
    };
    return this.directoryApi.AuthServicePostForgottenPassword(request);
  }

  public resetPassword (newPassword: string, resetToken: string): Observable<any> {
    return this.directoryApi.AuthServicePostResetPassword({ Token: resetToken, NewPassword: newPassword });
  }

  private logInHandler (subject: Subject<IAuthLoginResponse>, postPayload: any, successHandler: Function, errorHandler: Function): void {
    this.directoryApi.setHttpProvider(this.http);
    this.directoryApi.AuthServicePostToken(JSON.stringify(postPayload))
      .subscribe((response: IOauthTokenResponse) => {
        successHandler.call(this, response);
        subject.next(new AuthLoginResponse(response));
        subject.complete();
      }, (error) => {
        errorHandler.call(this, error);
        subject.error(error);
        subject.complete();
      });
  }

  private onLogInSuccess (response: IOauthTokenResponse): void {
    if (this.authMode !== AuthMode.Mobile) {
      if (this.rememberMe) {
        this.storageService.setLocalStorageItem(REFRESH_TOKEN_KEY, response.refresh_token);
      } else {
        this.storageService.removeSessionStorageItem(REFRESH_TOKEN_KEY);
        this.storageService.setSessionStorageItem(REFRESH_TOKEN_KEY, response.refresh_token);
      }
    }
    accessToken = response.access_token;
    userId = response.user_id;
    storedUsername = response.user_name;
    userPermissions = response.user_permissions ? response.user_permissions.split(',') : [];
    AADLogin = response.user_source ? response.user_source.toLowerCase() === 'azuread' : false;

    this.scheduleReAuth(response.expires_in * 1000 - REAUTH_OFFSET);

    if (this.analyticsService.isAvailable()) {
      this.analyticsService.setUserId(userId);
      this.analyticsService.setDimension(1, userId);
      this.analyticsService.submitEvent('User', 'Login', 'Success');
    }
  }

  private onLogInFail (response: IOauthTokenResponse): void {
    this.storageService.clearSessionStorageStore();
  }

  private scheduleReAuth (delta: number): void {
    if (reAuthTimer) {
      clearTimeout(reAuthTimer);
    }

    reAuthTimer = setTimeout(this.reAuth.bind(this), delta);
  }

  private onReAuthSuccess: Function = this.onLogInSuccess;

  private onReAuthFail (response: Response): void {
    // If auth is disabled, do not log out
    if (this.authMode === AuthMode.Bypass) {
      return;
    }
    // Only log out on unauthorised response
    if (response.status === 401) {
      this.logOut('unauthorised');
    }
  }

  public logOut (error: string = undefined): void {
    if (accessToken) {
      this.directoryApi.setHttpProvider(this.injector.get("HttpWrapperService"));
      this.directoryApi.AuthServiceLogOut().subscribe(
        response => this.logOutSuccess(response, error),
        errorResponse => this.logOutFailure(errorResponse, error)
      );
    } else {
      this.analyticsService.submitEvent('User', 'Logout', 'Success');
      this.doLogOut(error);
    }
  }

  private logOutSuccess (response: any, error: string) : void {
    this.directoryApi.setHttpProvider(this.http);
    this.analyticsService.submitEvent('User', 'Logout', 'Success');
    this.doLogOut(error);
  }

  private logOutFailure (response: any, error: string) : void {
    this.directoryApi.setHttpProvider(this.http);
    this.analyticsService.submitEvent('User', 'Logout', 'Failed');
    this.doLogOut(error);
  }

  private doLogOut (error: string) {
    accessToken = undefined;
    this.storageService.clearLocalStorageStore();
    this.storageService.clearSessionStorageStore();
    this.loadSiteRoot(error);
  }

  public changePasswordAndReAuth (oldPassword: string, newPassword: string): Observable<any> {
    this.directoryApi.setHttpProvider(this.injector.get("HttpWrapperService"));
    return this.directoryApi.AuthServicePostChangePassword({
      OldPassword: oldPassword,
      NewPassword: newPassword
    }).pipe(mergeMap(
      result => this.changePasswordSuccess(newPassword, result)
    ));
  }

  private changePasswordSuccess (newPassword: string, result: any): Observable<any> {
    const username = storedUsername;
    const password = newPassword;
    return this.directoryApi.AuthServiceLogOut().pipe(mergeMap(
      result => {
        this.directoryApi.setHttpProvider(this.http);
        return this.logIn({ username, password }, this.rememberMe);
      }
    ));
  }

  private changePasswordFailure (result): Observable<any> {
    this.directoryApi.setHttpProvider(this.http);
    return throwError(()=>new Error(result));
  }

  public isLoggedIn (): Promise<boolean> {
    // If we have a refresh token but no access token, attempt re-auth
    return new Promise((resolve: Function) => {
      if (this.authMode === AuthMode.Mobile) {
        if (this.getAccessToken()) {
          resolve(true);
        } else {
          const response = JSON.parse((<any>window).GloodooMob.GetAuthToken());
          accessToken = response.accessToken;
          userId = response.userId;
          if (accessToken) {
            resolve(true);
          } else {
            resolve(false);
          }
        }
      } else {
        if (this.getRefreshToken()) {
          if (this.getAccessToken()) {
            resolve(true);
          } else {
            this.reAuth().subscribe(resolve.bind(this, true), resolve.bind(this, false));
          }
        } else {
          resolve(false);
        }
      }
    });
  }

  private loadSiteRoot (error: string = undefined): void {
    window.location.replace(!!error ? `?error=${ error }` : '');
  }

  // refresh token when coming back online from offline - needed for iframe apps to get valid token
  private onOnline () {
    if (this.getRefreshToken()) {
      this.reAuth();
    }
  }
}
