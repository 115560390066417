import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { LocalizationService } from './localization.service';

@Injectable()
export class LocalizationGuard implements CanActivate {
  constructor(private localizationService: LocalizationService) {}

  public canActivate (): Promise<boolean> {
    return new Promise(resolve => {
      let currLanguage = this.localizationService.currentLanguage.getValue();
      this.localizationService.setLanguage(currLanguage);       
      resolve(true);
      });
    }
}