import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { IModalComponent} from '../../../notices/index';


@Component({
  selector: 'inactivity-modal',
  styleUrls: ['inactivity-modal.component.scss'],
  template:`
    <div class="inactivity-modal__container">
      <h1>Inactive</h1>
      <p>You will be logged out in</p>
      <h2>{{ countdownTime }}</h2>
      <p>seconds</p>
    </div>
  `
})
export class InactivityModalComponent implements IModalComponent {
  public blockClose: boolean = true;

  private timer: any;
  private isIE9: boolean = (<any>window).XDomainRequest;


  @Input() countdownTime: number;
  @Input() closeModal: Function;
  @Input() acceptCallback: Function;

  constructor (private changeDetector: ChangeDetectorRef) {
  }

  public ngOnInit (): void {
    this.timer = setInterval(() => {
      if(!this.countdownTime) {
        clearInterval(this.timer);
        return;
      }
      this.countdownTime--;
      if (this.isIE9) {
        this.changeDetector.detectChanges();
      }
    }, 1000);
  }
}
