import { Injectable, Type, ComponentFactoryResolver, Injector } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

const noop = () => {};
let nextUniqueId = 0;

export interface IModalComponent {
  closeModal: Function;
  blockClose?: boolean; // allow/block keys/mouse to close modal
}

export class ModalConfig {
  public id = nextUniqueId++;

  constructor (
    public component: Type<IModalComponent>,
    public attributes: any = {},
    public cmpResolver? : ComponentFactoryResolver,
    public injector? : Injector
  ) {}
}

const _modal: BehaviorSubject<ModalConfig> = new BehaviorSubject<ModalConfig>(undefined);

@Injectable()
export class ModalService {
  public modal = _modal.asObservable();

  public displayModal (modal: ModalConfig): void {
    if (_modal.getValue() === undefined) {
      let body = document.getElementById('documentBody');
      if (body) {
        body.style.overflow = 'hidden';
      }
      _modal.next(modal);
    } else {
      console.warn('Could not create the modal. Another modal is currently visible!');
    }
  }

  public closeModal (): void {
    let body = document.getElementById('documentBody');
    if (body) {
      body.style.overflow = 'auto';
    }
    _modal.next(undefined);
  }
}
