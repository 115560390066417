import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class LocalizationService {
  constructor (private translateService: TranslateService) {
    this.translateService.addLangs(['fi', 'en']);
    this.translateService.setDefaultLang('en');    
  }

  public currentLanguage: BehaviorSubject<string> = new BehaviorSubject('en');

  public getTranslateService (): TranslateService {
    return this.translateService;
  }

  public setLanguage (lang: string): void {
    this.translateService.use(lang);  
  }
}