import { Component } from '@angular/core';

@Component({
  styleUrls: ['not-found.component.scss'],
  template: `
    <mat-card class="not-found__card">
      <div class="not-found">
        <div class="not-found__grphic-column">
          <img class="not-found__graphic" role="presentation" src="/assets/errors/sad.svg"/>
        </div>
        <div class="not-found__details-column">
          <h1>Oops!</h1>
          <p>We can't seem to find the page you're looking for.</p>
        </div>
      </div>
    </mat-card>
  `
})
export class NotFoundComponent {}
