import { Injectable, OnInit } from '@angular/core';

const dateYear = new Date();
dateYear.setFullYear(dateYear.getFullYear() + 1);

@Injectable()
export class StorageService {

 // localStorage getters & setters
  public setLocalStorageItem (key: string, value: string): void {
    let encodedLSVal = encodeURIComponent(value);
    localStorage.setItem(key, encodedLSVal);
  }

  public getLocalStorageItem (key: string): string {
    const item = localStorage.getItem(key);
    if (item) {
      return decodeURIComponent(item);
    }
    return null;
  }

  public removeLocalStorageItem(key: string): void {
    localStorage.removeItem(key);
  }

  public clearLocalStorageStore(): void {
    localStorage.clear();
  }

  // sessionStorage getters & setters
  public setSessionStorageItem (key: string, value: string): void {
    let encodedSSVal = encodeURIComponent(value);
    sessionStorage.setItem(key, encodedSSVal);
  }

  public getSessionStorageItem (key: string): string {
    const item = sessionStorage.getItem(key);
    if (item) {
      return decodeURIComponent(item);
    }
    return null;
  }

  public removeSessionStorageItem(key: string): void {
    sessionStorage.removeItem(key);
  }

  public clearSessionStorageStore(): void {
    sessionStorage.clear();
  }

}
