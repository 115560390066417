import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs';
import { ConfigService } from '../../core/services/config.service';
import { HttpWrapperService } from '../../auth/services/http.wrapper.service';
import { MyTenantApiClientService, IIdentityTenant } from '../../../api/mytenant/index';

export { IIdentityTenant } from '../../../api/mytenant/index';

@Injectable()
export class TenantConfigService {
  private tenantCache: Observable<IIdentityTenant>;

  constructor (
    private api: MyTenantApiClientService,
    private httpWrapper: HttpWrapperService,
    private configService: ConfigService
  ) {
    this.api.setHttpProvider(this.httpWrapper);
    this.api.setApiDomain(this.configService.getValue('core', 'ApiDirectory'));
  }

  public get tenant (): Observable<IIdentityTenant> {
    return this.tenantCache ? this.tenantCache : this.requestMyTenant();
  }

  private requestMyTenant (): Observable<IIdentityTenant> {
    return this.api.MyTenantServiceGetMyTenant()
      .pipe(tap((tenant: IIdentityTenant) => { this.tenantCache = of(tenant); }));
  }
}
