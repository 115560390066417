import { Routes } from '@angular/router';

export const adaptRoutes: Routes = [

  {
    path: 'app',
    loadChildren: () => {
      return import('portal').then(m => m.PortalModule)
    },
  //   children: [
  //      {
  //   path: 'useradmin',
  //    loadChildren: () => import('useradmin').then(m => m.UseradminModule)
  // },
    // ]

  },
 
 

]