import { Component, OnDestroy, OnInit, HostListener, ChangeDetectorRef, ViewChild, Input, ElementRef } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { FormGroup, FormGroupDirective, NgForm, FormControl, FormBuilder, Validators } from '@angular/forms';
import { DialogService } from '../../../notices/index';
import { ConfigService } from '../../../core/index';
import { KeyboardService, getActiveElementName } from '../../../core/services/keyboard.service';
import { Subscription } from 'rxjs';
import { AuthService } from '../../services/auth.service';
import { ActiveDirectoryService } from '../../services/activedirectory.service';
import { ModalConfig, ModalService } from '../../../notices/index';
import { TermsOfServiceModalComponent } from '../tos-modal/tos-modal.component';
import { TranslateService } from '@ngx-translate/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {MatCheckbox} from '@angular/material/checkbox';
import { TwoToneHolderComponent } from '../two-tone-holder/two-tone-holder.component';

@Component({
  selector: 'login-component',
  styleUrls: ['login.component.scss'],
  template: `
    <two-tone-container>
      <div class="login-view__login-panel__frame">
        <div *ngIf="!hasAuthenticationCode && tenantId && aadAvailable" class="login-view__login-panel__aad">
          <a [attr.href]="activeDirectoryLocation" class="login-view-ad__signin">
            <img src="assets/providers/sign-in-with-microsoft-light.svg" alt="sign in with Microsoft" />
          </a>
        </div>

        <form [formGroup]="form" *ngIf="!hasAuthenticationCode && !isStored">
          <input
            matInput
            label="Tenant ID"
            autoFocus="true"
            name="tenantIdOverride"
            formControlName="tenantIdOverride"
            *ngIf="!tenantId"/>
          <table class="login-view__table">
            <tr>
              <td class="login-view__login-panel__icon-column">
                <img src="assets/icons/user.svg" className="login-view__login-panel__icon {{ emailError()? 'login-view__login-panel__error' : '' }}" #userIcon />
              </td>
              <td>
                <mat-form-field class="login-view__login-panel__mat-field-full-width">
                  <mat-label>{{ 'core.login.email' | translate }}</mat-label>
                  <input
                    matInput
                    class="login-view__login-panel__input"
                    autofocus
                    type="email"
                    name="username"
                    [attr.disabled]="isSubmitting"
                    formControlName="username"
                    autocomplete="off"/>
                </mat-form-field>
              </td>
            </tr>
            <tr>
              <td class="login-view__login-panel__icon-column">
                <img src="assets/icons/password.svg" className="login-view__login-panel__icon {{ passwordError()? 'login-view__login-panel__error' : '' }}" #passwordIcon />
              </td>
              <td>
                <mat-form-field class="login-view__login-panel__mat-field-full-width">
                  <mat-label>Password</mat-label>
                  <input
                    matInput
                    class="login-view__login-panel__input"
                    type="{{ (!showPassword || isSubmitting)? 'password' : 'text' }}"
                    name="password"
                    formControlName="password"
                    [attr.disabled]="isSubmitting"
                    #password>
                  <mat-hint *ngIf="(this.keyboardService.capsLockState | async) && passwordFocused()">
                    Caps lock is ON
                  </mat-hint>
                </mat-form-field>
              </td>
            </tr>
            <tr *ngIf="!isSubmitting && loginFailed">
              <td colspan="2">
                <div
                  class="login-view__login-panel__error">
                  {{ errorMessage }}
                </div>
              </td>
            </tr>
            <tr
              *ngIf="!isSubmitting"
              class="login-view__login-panel__show-password {{ loginFailed? 'login-view__login-panel__show-password__error' : ''}}">
              <td class="login-view__login-panel__icon-column">
                <img
                  (click)="changePasswordType()"
                  src="assets/icons/show-password-{{ showPassword? 'in' : '' }}visible.svg"
                  class="login-view__login-panel__icon"/>
              </td>
              <td>
                <button type="button" (click)="changePasswordType()">
                  <span *ngIf="showPassword">
                    {{ 'core.login.hidePassword' | translate }}
                  </span>
                  <span *ngIf="!showPassword">
                    {{ 'core.login.showPassword' | translate }}
                  </span>
                </button>
              </td>
            </tr>
          </table>

          <div *ngIf="!isSubmitting">
            <div class="login-view__login-panel__button-group">
              <a
                href="#"
                class="login-view__login-panel__link"
                routerLink="/forgottenPassword">
                {{ 'core.login.forgottenPassword' | translate }}
              </a>
              <button
                mat-raised-button
                class="login-view__login-panel__button login-view__login-panel__button__primary"
                type="submit"
                (click)="onSubmit()"
                [disabled]="loginButtonDisabled()">
                {{ 'core.login.signIn' | translate }}
              </button>
            </div>
            <mat-checkbox
              class="login-view__login-panel__remember-me-on-this__checkbox"
              aria-label="{{ 'core.login.rememberMe' | translate }}"
              #rememberMe>
              {{ 'core.login.rememberMe' | translate }}
            </mat-checkbox>
          </div>
        </form>
        <mat-progress-spinner
          *ngIf="isSubmitting"
          class="login-view__login-panel__spinner"
          color="primary"
          diameter="50"
          stroke="3"
          mode="indeterminate">
        </mat-progress-spinner>
        <!-- TODO - style this when AAD comes to Accenture IWOS -->
        <p class="login-view__login-panel__authenticate"
          *ngIf="isSubmitting && hasAuthenticationCode && !isStored">
          {{ 'core.login.submittingMessage' | translate }}
        </p>
      </div>
    </two-tone-container>
  `
})


export class LoginComponent implements OnDestroy, OnInit {
  form: FormGroup;
  loginFailed: boolean = false;
  errorMessage: string;
  isSubmitting: boolean;
  paramsSubscription: Subscription;
  tenantId: string;
  tenantDomain: string;
  domainName: string;
  forgottenPasswordLink: string;
  onError: boolean;
  logoPath: string;
  DEFAULT_LOGO: string = 'assets/brand/logo-simple.svg';
  backgroundStyle: Object;
  rememberMeChecked: boolean = false;
  activeDirectoryLocation: string;
  hasAuthenticationCode: boolean = false;
  aadAvailable: boolean = false;
  showPassword = false;
  isStored: boolean = false;
 
  @ViewChild('rememberMe', {static: false} ) private rememberMeComponent: MatCheckbox;
  @ViewChild('password', {static: false}) private passwordInput: ElementRef;
  @ViewChild('userIcon', {static: false}) private userIconObject: ElementRef;
  @ViewChild('passwordIcon', {static: false}) private passwordIconObject: ElementRef;

  @HostListener('keyup', ['$event']) onKeyUp (event: KeyboardEvent) {
    if (event.keyCode === 13 && !this.onError)
      this.onSubmit();
  }
  keyboardService: KeyboardService;
  constructor (
    private authService: AuthService,
    private activeDirectoryService: ActiveDirectoryService,
    private changeDetector: ChangeDetectorRef,
    private configService: ConfigService,
    private dialog: MatDialog,
    private dialogService: DialogService,
    private fb: FormBuilder,
    keyboardService: KeyboardService,
    private modalService: ModalService,
    private router: Router,
    private route: ActivatedRoute,
    private translate: TranslateService
    ) {
    this.keyboardService=keyboardService;
    this.tenantId = authService.getTenantId();
    this.logoPath = this.getLogo();
    this.backgroundStyle = this.getBackgroundStyle();

    this.form = fb.group({
      'username': [''],
      'password': ['']
    });

    if (!this.tenantId) {
      this.form.addControl('tenantIdOverride', new FormControl('', Validators.required));
      (<FormControl>this.form.controls['tenantIdOverride']).valueChanges.subscribe((value) => {
        this.loginFailed = false;
      });
    }

    (<FormControl>this.form.controls['username']).valueChanges.subscribe((value) => {
      this.resetFormErrors();
    });
    (<FormControl>this.form.controls['password']).valueChanges.subscribe((value) => {
      this.resetFormErrors();
    });

    const dotIndex = location.hostname.indexOf('.');
    this.tenantDomain = location.hostname.substring(0, dotIndex);
    this.domainName = location.hostname.substring(dotIndex, location.hostname.length);
  }

  private resetFormErrors(){
    this.loginFailed = false;
    (<FormControl>this.form.controls['username']).setErrors(null);
    (<FormControl>this.form.controls['password']).setErrors(null);
  }

  public ngOnInit (): void {

    this.paramsSubscription = this.route.queryParams.subscribe(this.onParamsChange.bind(this));

    this.hasAuthenticationCode = this.activeDirectoryService.hasAuthenticationCode();

    if (this.hasAuthenticationCode) {
      this.isSubmitting = true;

      this.authService.logInAd({
        code: this.activeDirectoryService.getAuthenticationCode(),
        redirectUri: this.activeDirectoryService.redirectUri()
      }).subscribe(this.onLoginSuccess.bind(this), this.onLogInFailed.bind(this));
    } else {
      const storedPassword = sessionStorage.getItem('stored_password');
      const storedUsername = sessionStorage.getItem('stored_username');
      if ( storedPassword && storedUsername) {
        this.isStored = true;
        this.hasAuthenticationCode = true;

        (<FormControl>this.form.controls['username']).setValue(storedUsername);
        (<FormControl>this.form.controls['password']).setValue(storedPassword);
        sessionStorage.removeItem('stored_password');
        sessionStorage.removeItem('stored_username');

        this.onSubmit();
      } else {
        this.activeDirectoryLocation = this.activeDirectoryService.location();
        this.activeDirectoryService.isAvailable().subscribe(available => {
          this.aadAvailable = available;
        });
      }
    }
  }

  public ngOnDestroy (): void {
    this.paramsSubscription.unsubscribe();   
  }

  public onSubmit (): void {
    let username = (<FormControl>this.form.controls['username']).value;
    let password = (<FormControl>this.form.controls['password']).value;

    // save checked value in case TOS needs to be accepted or password reset - checkbox viewchild is not available since submitting
    if (!this.rememberMeChecked && this.rememberMeComponent) {
      this.rememberMeChecked = this.rememberMeComponent.checked;
    }

    if (username == '' || password == '') {
      this.onLogInFailed();
      return;
    }

    if (!this.isSubmitting) {
      if((<FormControl>this.form.controls['tenantIdOverride'])) {
        this.authService.setTenantId((<FormControl>this.form.controls['tenantIdOverride']).value);
      }

      this.isSubmitting = true;
      this.authService.logIn({ username, password }, this.rememberMeChecked)
        .subscribe(this.onLoginSuccess.bind(this), this.onLogInFailed.bind(this));
    }
  }

  emailError(): boolean {
    return !(<FormControl>this.form.controls['username']).valid;
  }

  passwordError(): boolean {
    return !(<FormControl>this.form.controls['password']).valid;
  }

  onParamsChange (params: Params): void {
    if (params['error']) {
      this.dialogHandleError(params['error']);
    }
  }

  private onLoginSuccess (): void {
    this.router.navigateByUrl(this.authService.redirectUrl);   
  }

  private onLogInFailed (response: any = undefined): void {
    let responseData = response && response.error;

    setTimeout(() => {
      if (!responseData || (responseData.error !== 'password_expired' && responseData.error !== 'terms_of_service') ) {
        this.isSubmitting = null;
        if (this.loginFailed) {
          (<FormControl>this.form.controls['username']).setErrors(['invalid']);
          (<FormControl>this.form.controls['password']).setErrors(['invalid']);
        }
      }
    }, 1000);

    this.hasAuthenticationCode = false;
    this.activeDirectoryLocation = this.activeDirectoryService.location();

    if (!response || response.status === 401) {
      if (responseData && responseData.error === 'password_expired') {
        sessionStorage.setItem('resettoken', responseData.error_token);
        this.router.navigate(['resetPassword']);
        this.isStored = false;
      } else if (responseData && responseData.error === 'terms_of_service') {
        const tosDialog: MatDialogRef<TermsOfServiceModalComponent> = this.dialog.open(TermsOfServiceModalComponent, {
          disableClose: true,
          autoFocus: true,
          closeOnNavigation: false,
          data: {
            errorToken: responseData.error_token
          }
        });

        this.dialog.afterAllClosed.subscribe(this.onAcceptTos.bind(this));
      } else {
        this.loginFailed = true;

        let translateKey = 'core.login.errors.invalid_grant'
        if (responseData && responseData.error === 'account_locked') {
            translateKey = 'core.login.errors.account_locked';
        }
        else if (responseData && responseData.error === 'account_locked_warning') {
            translateKey = 'core.login.errors.account_locked_warning';
        }

        this.translate.get(translateKey).subscribe((res: string) => {
          this.errorMessage = res;
        });
        this.isStored = false;
      }
    } else {
      this.inlineHandleError(responseData);
      this.isStored = false;
    }

  }

  private inlineHandleError (responseData: any) {

    if (responseData){      
      if (responseData && responseData.error) {
        this.loginFailed = true;

        this.translate.get('core.login.errors.' + responseData.error).subscribe((res: string) => {
          this.errorMessage = res;
        });
      } else {
        this.translate.get('core.login.dialogs.unexpectedError').subscribe((res: any) => {
          this.dialogService.showDialog(res.message, res.button1, res.button2, res.title, '/assets/errors/icon_warning.svg');
        });
      }
    }
  }

  private onAcceptTos (): void {
    this.isSubmitting = null;
    this.onSubmit();
  }

  onResetPassword (newPassword: string): void {
    (<FormControl>this.form.controls['password']).setValue(newPassword);
    this.isSubmitting = null;
    this.onSubmit();
  }

  dialogHandleError (error: string): void {
    this.isSubmitting = null;
    this.onError = true;
    switch (error) {
      case 'unauthorised':
      case 'forbidden':
      case 'inactive':
        this.translate.get('core.login.dialogs.' + error ).subscribe((res: any) => {
          this.dialogService.showDialog(res.message, res.button1, res.button2, res.title, '/assets/errors/icon_warning.svg');
        });
        break;
      case 'unknown':
        this.translate.get('core.login.dialogs.unknown' ).subscribe((res: any) => {
          this.dialogService.showDialog(res.message, res.button1, res.button2, res.title, '/assets/errors/icon_warning.svg');
        });
        break;
      default:
        this.translate.get('core.login.dialogs.unknown' ).subscribe((res: any) => {
          this.dialogService.showDialog(res.message, res.button1, res.button2, res.title, '/assets/errors/icon_warning.svg');
        });
        break;
    }
  }

  closeError (): void {
    this.onError = false;
    window.location.hash = '';
  }

  getLogo (): string {
    const tenantLogo = this.configService.getValue('core', 'branding').loginScreenLogo;
    return tenantLogo ? tenantLogo : this.DEFAULT_LOGO;
  }

  getBackgroundStyle(): Object {
    const branding = this.configService.getValue('core', 'branding');
    const image = branding.loginScreenBackground;
    const color = branding.loginScreenBackgroundColor;
    const position = branding.loginScreenBackgroundPosition;
    let style = {};
    if (image) {
      style['backgroundImage'] = `url(${image})`;
      if (position) {
        style['backgroundPosition'] = position;
      }
      if (color) {
        style['backgroundColor'] = color;
      }
      return style;
    }
    if (color) {
      style['backgroundColor'] = color;
      return style;
    }
    return undefined;
  }

  forgottenPassword () {
    window.location.href = this.forgottenPasswordLink;
  }

  changePasswordType () {
    this.showPassword = !this.showPassword;
  }

  passwordFocused (): boolean {
    return getActiveElementName() === "password";
  }

  loginButtonDisabled (): boolean {
    return (
      (<FormControl>this.form.controls['password']).value == '' ||
      (<FormControl>this.form.controls['username']).value == '' ||
      this.loginFailed
    );
  }
}
