import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

let config: any = {};
let reservedIds: Array<string> = [];

@Injectable()
export class ConfigService {
  constructor() {}

  public getValue (appId: string, key: string): any {
    if (config && config[appId])
      return config[appId][key];
  }

  public setValue (appId: string, key: string, value: any): boolean {
    if (config[appId]) {
      if (config[appId][key] && reservedIds.indexOf(appId) > -1) {
        return false;
      }
    } else {
      config[appId] = {};
    }
    config[appId][key] = value;
    return true;
  }

  public addConfig (appId: string, appConfig: any, readOnly: boolean = false) {
    if (reservedIds.indexOf(appId) === -1) {
      config[appId] = appConfig;
      if (readOnly)
        reservedIds.push(appId);
    } else {
      console.warn(`The config could not be set for '${appId}'. Read only configuration for it already exists.`);
    }
  }
}
