import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  styleUrls: ['holding.component.scss'],
  template: `
    <div class="core-holding">
      <mat-spinner diameter="40"></mat-spinner>
    </div>
  `
})
export class HoldingComponent {
  private timer;

  constructor(private router: Router) {}

  public ngOnInit () {
    // if user refreshed the page while loading, he might endup to this page indefinitely - route to app base if too long
    this.timer = setTimeout(() => {
      this.router.navigate(['/app/']);
    }, 15000);
  }

  public ngOnDestroy () {
    clearTimeout(this.timer);
  }
}
