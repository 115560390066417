import { Injectable, ComponentFactoryResolver } from '@angular/core';
import { DialogComponent } from '../components/dialog/dialog.component';
import { MatDialog, MatDialogRef, MatDialogConfig } from '@angular/material/dialog';


@Injectable()
export class DialogService {
  constructor (private dialog: MatDialog) {}

  public showDialog (message: string, resolveVerb: string, rejectVerb: string = undefined, title: string = undefined, image: string = undefined): Promise<any> {
    return new Promise<void>((resolve, reject) => {
      const config: MatDialogConfig = {
        data: {
          title: title,
          message: message,
          image: image,
          resolveVerb: resolveVerb,
          rejectVerb: rejectVerb,
        }
      };
      const dialogRef: MatDialogRef<DialogComponent> = this.dialog.open(
        DialogComponent,
        config
      );
      if (dialogRef && dialogRef.afterClosed)
        dialogRef.afterClosed().subscribe(resolved => {
          resolved ? resolve() : reject();
        });
    });
  }
}
