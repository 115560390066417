import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { AuthService, AuthMode } from './auth.service';
import { ConfigService } from '../../core/services/config.service';
import { InactivityLogoutService } from '../../core/services/inactivity-logout.service';
import { HttpWrapperService } from './http.wrapper.service';
import { ProfileApiClientService, IUserProfile } from '../../../api/profile/index';

const _hasSession = new BehaviorSubject<boolean>(false);
const _currentUser: BehaviorSubject<IUserProfile> = new BehaviorSubject<IUserProfile>(undefined);

export { IUserProfile } from '../../../api/profile/index';

@Injectable()
export class SessionService {
  public hasSession = _hasSession.asObservable();
  public currentUser = _currentUser.asObservable();

  constructor(
    private configService: ConfigService,
    private httpWrapper: HttpWrapperService,
    private inactivityService: InactivityLogoutService,
    private auth: AuthService,
    private profileService: ProfileApiClientService
  ) {
    this.profileService.setApiDomain(configService.getValue('core', 'ApiDirectory'));
    this.profileService.setHttpProvider(httpWrapper);
  }


  public setProfileImageUrl (url): void {
    let newUser = _currentUser.getValue();
    if (!newUser) return;
    newUser.Image = url;
    _currentUser.next(newUser);
  }

  public getCurrentUser (): Promise<any> {
    return new Promise((resolve, reject) => {
      // resolve to bypass login screen for unauthorized applications
      if (this.auth.authMode === AuthMode.Bypass) {
        resolve(null);
      }

      this.auth.isLoggedIn().then(isLoggedIn => {
        if (!isLoggedIn) {
          _hasSession.next(false);
          reject();
        } else if (!_currentUser.getValue()) {
          this.profileService.ProfileServiceGetUserProfileById(this.auth.getUserId())
            .subscribe((currentUser: IUserProfile) => {
              _currentUser.next(currentUser);
              _hasSession.next(true);
              resolve(currentUser);
              this.inactivityService.checkAndStartInactivityLogout();
            }, () => {
              _hasSession.next(false);
              reject();
            });
        } else {
          resolve(_currentUser.getValue());
          this.inactivityService.checkAndStartInactivityLogout();
        }
      });
    });
  }

  public updateCurrentUser (): void {
    this.profileService.ProfileServiceGetUserProfileByUserName('')
      .subscribe((currentUser) => {
        _currentUser.next(currentUser);
      });
  }
}
