import { Component } from '@angular/core';

@Component({
  selector: 'lights-out',
  styleUrls: ['lights-out.component.scss'],
  template: `
    <div class="lights-out"></div>
  `
})
export class LightsOutComponent {
}
