import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GDBootstrapModule, GDWebCoreModule, GloodooComponent, MaterialModule } from 'adapt-web-core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { adaptRoutes } from './app.routing';
@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(adaptRoutes),
    GDWebCoreModule,
    MaterialModule,
    GDBootstrapModule
  ],
  
  bootstrap: [ GloodooComponent ]
})
export class PortalModule { }
