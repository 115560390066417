import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

export interface IToastComponent {
  closeToast: Function;
}

export class ToastConfig {
  public attributes: any = {};

  constructor (text: string, title?: string, duration?: number) {
    this.attributes.duration = duration ? duration : 3000;
    this.attributes.text = text;
    this.attributes.title = title;
  }
}

@Injectable()
export class ToastService {
  constructor(
    private snackBar: MatSnackBar
  ) {}

  public showToast (toast: ToastConfig): void {
    this.snackBar.open(toast.attributes.text, null, { duration: toast.attributes.duration });
  }
}
