import { Component, ComponentFactory, ComponentRef, ComponentFactoryResolver, ChangeDetectorRef, OnInit, OnDestroy, ViewChild, ViewContainerRef } from '@angular/core';
import { trigger, state, transition, style, animate } from '@angular/animations';
import { Subscription } from 'rxjs';
import { ModalService, ModalConfig, IModalComponent } from '../../services/modal.service';
import * as _ from 'lodash';

@Component({
  selector: 'modal-outlet',
  styleUrls: ['modal-outlet.component.scss'],
  template: `
    <div class="modal-outlet">
      <lights-out *ngIf="currentModal != undefined" [@fadeInOut]="'in'"  (click)="handleClick($event)"></lights-out>
      <div #target></div>
    </div>
  `,
  animations: [
    trigger('fadeInOut', [
      state('in', style({ opacity: 1 })),
      transition('void => *', [
        style({opacity: 0}),
        animate('300ms ease-in-out')
      ]),
      transition('* => void', [
        animate('300ms ease-in-out', style({ opacity: 0 }))
      ])
    ])
  ]
})
export class ModalOutletComponent implements OnInit, OnDestroy {
  private msSubscription: Subscription;
  currentModal: ComponentRef<IModalComponent>;
  private isIE9: boolean = (<any>window).XDomainRequest;

  @ViewChild('target', { read: ViewContainerRef, static:false }) viewContainer:ViewContainerRef;

  constructor (private modalService: ModalService, private cmpResolver: ComponentFactoryResolver, private changeDetector: ChangeDetectorRef) {}

  private onModalChange (modalConfig: ModalConfig): void {
    if (this.currentModal) {
      this.currentModal.destroy();
      this.currentModal = undefined;

      if (this.isIE9) {
        this.changeDetector.detectChanges();
      }
    }

    if (modalConfig) {
      const factory = (modalConfig.cmpResolver || this.cmpResolver).resolveComponentFactory(modalConfig.component);
      this.currentModal = this.viewContainer.createComponent(factory, 0, modalConfig.injector || this.viewContainer.injector);
      this.currentModal.instance.closeModal = this.modalService.closeModal.bind(this.modalService);

      _.each(modalConfig.attributes, (value, attribute) => {
        this.currentModal.instance[attribute] = value;
      });
    }

    if (this.isIE9) {
      this.changeDetector.detectChanges();
    }
  }

  public ngOnInit (): void {
    this.msSubscription = this.modalService.modal
      .subscribe(this.onModalChange.bind(this));
    document.addEventListener('keydown', this.handleKeys, false);
  }

  public ngOnDestroy (): void {
    this.msSubscription.unsubscribe();
    document.removeEventListener('keydown', this.handleKeys);
  }

  private handleKeys = e => {
    const ESC = 27;
    if (e.keyCode === ESC && !this.currentModal.instance.blockClose) {
      this.currentModal.instance.closeModal();
    }
  }

  handleClick = e => {
    if (this.currentModal !== undefined) {
      // TODO: get element from modal and use as selector
      if(!document.querySelector('.gd-modal').contains(e.target) && !this.currentModal.instance.blockClose) {
        this.currentModal.instance.closeModal();
      }
    }
  }
}
