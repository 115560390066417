import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'gd-dialog',
  styleUrls: ['dialog.component.scss'],
  template: `
    <div class="gd-dialog">
      <div class="gd-dialog__image" *ngIf="image">
        <img [src]="image" role="presentation"/>
      </div>
      <div class="gd-dialog__content">
        <h2>{{ data.title }}</h2>
        <p>{{ data.message }}</p>
        <div class="gd-dialog__actions">
          <button
            mat-raised-button
            (click)="onReject()"
            *ngIf="data.rejectVerb != undefined">
            {{ data.rejectVerb }}
          </button>
          <button
            mat-raised-button
            color="primary"
            (click)="onResolve()">
            {{ data.resolveVerb }}
          </button>
        </div>
      </div>
    </div>
  `
})
export class DialogComponent {
  data:any;
  image:any;
  constructor(
    private matDialogRef: MatDialogRef<DialogComponent>,
    @Inject(MAT_DIALOG_DATA) data: any
  ) { this.data=data;}

  onResolve (): void {
    this.matDialogRef.close(true);
  }

  onReject (): void {
    this.matDialogRef.close(false);
  }
}
