import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { FormGroupDirective, NgForm, FormControl } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';

@Injectable()
export class KeyboardService {
  private _capsLockState: Subject<boolean> = new Subject();

  constructor() {
    ['keydown', 'keyup'].forEach(type => {
      document.addEventListener(type, this.checkCapsLock);
    });
  }

  get capsLockState(): Observable<boolean> {
    return this._capsLockState as Observable<boolean>;
  }

  private checkCapsLock = (event: any) => {
    let caps = event.getModifierState && event.getModifierState('CapsLock');
    this._capsLockState.next(caps);
  }
}

export const getActiveElementName = () => {
  const item = document.activeElement.attributes.getNamedItem('name');
  return item? item.value : '';
};

// Error when password input is focused and caps lock is on
export class CapsLockErrorStateMatcher implements ErrorStateMatcher {
  
  private capsLockOn: boolean;
  private activeElementNameToCheck: string;
  constructor (private keyboardService: KeyboardService, fieldName: string) {
    this.activeElementNameToCheck = fieldName;
    keyboardService.capsLockState.subscribe(state => {
      this.capsLockOn = state;
    });
  }

  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    if (control.invalid) {
      return true;
    }
    return getActiveElementName() === this.activeElementNameToCheck && this.capsLockOn;
  }
}