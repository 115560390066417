import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

declare let ga: Function;

@Injectable()
export class AnalyticsService {

  public isAvailable (): boolean {
    if ((<any>window).ga && ga instanceof Function) {
      return true;
    }
    return false;
  }

  public setUserId (userId: string): void {
    ga('set', 'userId', userId);
  }

  public setDimension (index: number, value: string) : void {
    ga('set', 'dimension' + index, value);
  }

  public setPage (url: string) {
    if (this.isAvailable()) {
      ga('set', 'page', url);
      ga('send', 'pageview');
    }
  }

  public submitEvent (
    eventCategory: string,
    eventAction: string,
    eventLabel: string = null,
    eventValue: number = null) {
    if (this.isAvailable()) {
      ga('send', 'event', eventCategory, eventLabel, eventAction, eventValue);
    }
  }
}
