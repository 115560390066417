import { Injectable, Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import * as models from './models/MyTenant_models';

export * from './models/MyTenant_models';

@Injectable({
  providedIn: 'root'
})
export class MyTenantApiClientService {

  domain: string;

  constructor (public http: HttpClient) {}

  public setApiDomain (domain): void {
    this.domain = domain;
  }

  public setHttpProvider (httpProvider): void {
    this.http = httpProvider;
  }

  /**
  * 
  * @method GET
  * @name MyTenantServiceGetMyTenant
  *
  */
  public MyTenantServiceGetMyTenant (): Observable<any> {
    const uri = `/mytenant`;
    const payload = {};

    const queryParameters = new HttpParams();
    let headers = new HttpHeaders();

    const options = {
      params: queryParameters,
      headers: headers,
      responseType: 'json' as 'json'
    };


    return this.http
      .request('GET', this.domain + uri, options);
  }

  /**
  * 
  * @method POST
  * @name MyTenantServiceBindToExternalIdentityProvider
  * @param {models.IBindToExternalIdentityProviderRequest} request - 
  *
  */
  public MyTenantServiceBindToExternalIdentityProvider (request: models.IBindToExternalIdentityProviderRequest | string): Observable<any> {
    const uri = `/mytenant/bindtoexternalidentityprovider`;
    const payload = {};

    const queryParameters = new HttpParams();
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');

    const options = {
      params: queryParameters,
      headers: headers,
      responseType: 'json' as 'json'
    };

    if (typeof request === 'object') {
      options['body'] = JSON.stringify(request);
    } else {
      options['body'] = request;
    }

    return this.http
      .request('POST', this.domain + uri, options);
  }

  /**
  * 
  * @method POST
  * @name MyTenantServiceUnbindFromExternalIdentityProvider
  * @param {models.IUnbindFromExternalIdentityProviderRequest} request - 
  *
  */
  public MyTenantServiceUnbindFromExternalIdentityProvider (request: models.IUnbindFromExternalIdentityProviderRequest | string): Observable<any> {
    const uri = `/mytenant/unbindfromexternalidentityprovider`;
    const payload = {};

    const queryParameters = new HttpParams();
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');

    const options = {
      params: queryParameters,
      headers: headers,
      responseType: 'json' as 'json'
    };

    if (typeof request === 'object') {
      options['body'] = JSON.stringify(request);
    } else {
      options['body'] = request;
    }

    return this.http
      .request('POST', this.domain + uri, options);
  }


}
