import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule, FormBuilder } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { MaterialModule } from './material.module';

import { gloodooRoutes, gloodooViews } from './core.routing';



export * from './core/auth/index';
export * from './core/core/index';
export * from './core/notices/index';

export * from './api/profile/index';

export * from './material.module';



import {
  DirectoryApiClientService } from './api/directory/index';
import {
  ProfileApiClientService } from './api/profile/index';
import {
  MyTenantApiClientService } from './api/mytenant/index';
import {
  AnalyticsService,
  AppStatusService,
  ConfigService,
  Deferred404Guard,
  GDSourcePipe,
  GloodooComponent,
  LazyInjector,
  LocalizationService,
  LocalizationGuard,
  NotFoundComponent,
  NotAuthComponent,
  StorageService,
  TenantConfigService,
  InactivityLogoutService,
  KeyboardService } from './core/core/index';
import {
  AppAuthGuard,
  AuthService,
  ActiveDirectoryService,
  HttpWrapperService,
  LoginAuthGuard,
  InactivityModalComponent,
  TermsOfServiceModalComponent,
  TwoToneHolderComponent,
  SessionService } from './core/auth/index';
import {
  DialogComponent,
  LightsOutComponent,
  DialogService,
  ModalOutletComponent,
  ModalService,
  ToastService } from './core/notices/index';

@NgModule({ declarations: [
        DialogComponent,
        GDSourcePipe,
        LightsOutComponent,
        ModalOutletComponent,
        NotFoundComponent,
        NotAuthComponent,
    ],
    exports: [
        DialogComponent,
        GDSourcePipe,
        LightsOutComponent,
        ModalOutletComponent,
        NotFoundComponent,
        NotAuthComponent,
        MaterialModule,
        TranslateModule
    ], imports: [CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        TranslateModule.forRoot({
          loader: {
            provide: TranslateLoader,
            useFactory: HttpLoaderFactory,
            deps: [HttpClient]
        }
        }),
        MaterialModule], providers: [
        AnalyticsService,
        AuthService,
        ActiveDirectoryService,
        ConfigService,
        DialogService,
        DirectoryApiClientService,
        HttpWrapperService,
        LazyInjector,
        LocalizationService,
        ModalService,
        ProfileApiClientService,
        StorageService,
        SessionService,
        MyTenantApiClientService,
        TenantConfigService,
        ToastService,
        Title,
        FormBuilder,
        InactivityLogoutService,
        KeyboardService,
        // Http provider for auth service authenticated methods (circular dependency issue)
        { provide: "HttpWrapperService", useClass: HttpWrapperService },
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class GDWebCoreModule { }

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forRoot(gloodooRoutes, { useHash: false }),
    BrowserAnimationsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
    }
    }),
    GDWebCoreModule,
    MaterialModule
  ],
  providers: [
    AppAuthGuard,
    AppStatusService,
    Deferred404Guard,
    LocalizationGuard,
    LoginAuthGuard,
    provideHttpClient(withInterceptorsFromDi())
  ],
  declarations: [
    GloodooComponent,
    gloodooViews,
    InactivityModalComponent,
    TermsOfServiceModalComponent,
    TwoToneHolderComponent
  ],
  exports:[
    GloodooComponent
  ]
  // entryComponents: [
  //   InactivityModalComponent,
  //   TermsOfServiceModalComponent
  // ],
 // bootstrap: [ GloodooComponent ]
})
export class GDBootstrapModule { }

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}