import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs';
import { IIdentityTenant } from '../../../api/mytenant/index';
import { AuthService } from '../../auth/services/auth.service';
import { ConfigService } from './config.service';
import { InactivityModalComponent } from '../../auth/components/inactivity-modal/inactivity-modal.component';
import { ModalConfig, ModalService } from '../../notices/index';
import * as _ from 'lodash';

let inactivityTimerId: any;
let inactivityCheckTimerId: any;
let inactivityWarningTimerId: any;

@Injectable()
export class InactivityLogoutService {
  private tenantSubscription: Subscription;
  private inactivityTime: number;
  private countdownTime: number;
  private window: Window = window;
  private document: Document = document;
  private warning: boolean = false;
  private idleResetEvents: Array<string> = [
    'load',
    'mousemove',
    'touch',
    'click',
    'scroll',
    'keypress'
  ];

  constructor (
    private authService: AuthService,
    private configService: ConfigService,
    private modalService: ModalService
  ) {}

  public checkAndStartInactivityLogout (): void {
    this.clearIdleTimers();
    const timeInSecs = this.configService.getValue('core', 'inactivityLogoutSecs');
    if (timeInSecs) {
      this.countdownTime = timeInSecs > 120? 60 : Math.ceil(timeInSecs / 3)
      this.inactivityTime = this.parseTimeout(timeInSecs);

      if (this.startIdleTimers()) {
        this.removeIdleResetEvents();
        this.attachIdleResetEvents();
      }
    }
  }

  public restartIdleTimer (): void {
    if (this.warning) {
      this.modalService.closeModal();
      this.warning = false;
    }
    this.clearIdleTimers();
    this.startIdleTimers();
  }

  public clearIdleTimers (): void {
    if (inactivityTimerId) {
      this.window.clearTimeout(inactivityTimerId);
      this.removeIdleResetEvents();
      inactivityTimerId = undefined;
    }
    if (inactivityCheckTimerId) {
      this.window.clearTimeout(inactivityCheckTimerId);
      this.removeIframeIdleCheckResetEvents();
      inactivityCheckTimerId = undefined;
    }
    if (inactivityWarningTimerId) {
      this.window.clearTimeout(inactivityWarningTimerId);
      inactivityWarningTimerId = undefined;
    }
  }

  private startIdleTimers (): boolean {
    if (!inactivityTimerId) {
      inactivityTimerId = this.window.setTimeout(this.inactiveHandler.bind(this), this.inactivityTime);
      // In case of iframes used, pop up a 'glass' to check possible iframe usage half way through the inactivityTime. Remove if/when iframes will send activity events to portal.
      inactivityCheckTimerId = this.window.setTimeout(
        this.inactiveCheckHandler.bind(this),
        this.inactivityTime / 2
      );

      inactivityWarningTimerId = this.window.setTimeout(
        this.inactiveWarningHandler.bind(this),
        this.inactivityTime - this.countdownTime * 1000
      );
      return true;
    }
    return false;
  }

  private parseTimeout (claimTimeoutProperty: string): number {
    const timeout: number = parseInt(claimTimeoutProperty, 10) * 1000;
    return !Number.isNaN(timeout) && timeout > 0 ? timeout : undefined;
  }

  private inactiveHandler (): void {
    this.authService.logOut('inactive');
  }

  private inactiveCheckHandler (): void {
    this.removeIframeIdleCheckResetEvents();
    this.attachIframeIdleCheckResetEvents();
  }

  private inactiveWarningHandler (): void {
    this.warning = true;
    this.modalService.closeModal();
    this.modalService.displayModal(new ModalConfig(InactivityModalComponent, {
      countdownTime: this.countdownTime,
      acceptCallback: () => {
        this.restartIdleTimer();
      }
    }));
  }

  private attachIdleResetEvents (): void {
    _.each(this.idleResetEvents, (eventName: string): void => {
      this.window.addEventListener(eventName, this.restartIdleTimer.bind(this), false);
    });
  }

  private attachIframeIdleCheckResetEvents (): void {
    if (!this.document.getElementById('inactivityGlass')) {
      let inactivityGlass: HTMLElement = this.document.createElement('div');
      inactivityGlass.id = 'inactivityGlass';
      _.each(this.idleResetEvents, (eventName: string): void => {
        inactivityGlass.addEventListener(eventName, this.restartIdleTimer.bind(this), false);
      });
      this.document.body.appendChild(inactivityGlass);
    }
  }

  private removeIdleResetEvents (): void {
    _.each(this.idleResetEvents, (eventName: string): void => {
      this.window.removeEventListener(eventName, this.restartIdleTimer.bind(this), false);
    });
  }

  private removeIframeIdleCheckResetEvents (): void {
    let inactivityGlass: HTMLElement = this.document.getElementById('inactivityGlass');
    if (inactivityGlass) {
      _.each(this.idleResetEvents, (eventName: string): void => {
        inactivityGlass.removeEventListener(eventName, this.restartIdleTimer.bind(this), false);
      });
      inactivityGlass.parentElement.removeChild(inactivityGlass);
    }
  }
}
