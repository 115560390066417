import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  FormGroup,
  FormControl,
  FormBuilder,
  Validators,
  ValidatorFn
} from '@angular/forms';
import {MatInput} from '@angular/material/input';
import { AuthService } from '../../services/auth.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'forgotten-password-component',
  styleUrls: ['forgotten-password.component.scss'],
   host: {'class': 'forgotten-password'},
  template: `
    <two-tone-container [messages]="twoToneMessages">
      <div *ngIf="isSubmitting" class="forgotten-password__loading">
        <mat-progress-spinner
          class="forgotten-password__loading__spinner"
          color="primary"
          diameter="50"
          stroke="3"
          mode="indeterminate">
        </mat-progress-spinner>
      </div>
      <div *ngIf="!isSubmitting" class="forgotten-password__details">
        <div *ngIf="isError">
          <p>{{ apiErrorMessage }}</p>
          <a
            mat-raised-button
            href="#"
            routerLink="/login"
            class="forgotten-password__details__button forgotten-password__details__button__primary">
            {{ 'core.forgottenPassword.button.backToLogin' | translate }}
          </a>
        </div>

        <div *ngIf="!isComplete && !isError">
          <form [formGroup]="confirmFormGroup">
            <p>{{ 'core.forgottenPassword.message' | translate }}</p>
            <mat-form-field class="forgotten-password__details__mat-field-full-width">
              <mat-label>{{ 'core.forgottenPassword.email.placeholder' | translate }}</mat-label>
              <input
                id="forgottenPasswordEmailInput"
                matInput
                autofocus
                class="forgotten-password__details__input"
                type="email"
                name="email"
                formControlName="email"
                autocomplete="off"/>
              <mat-error *ngIf="isEmailError">
                {{ 'core.forgottenPassword.error.email' | translate }}
              </mat-error>
            </mat-form-field>
            <button
              mat-raised-button
              (click)="submitEmail($event)"
              [disabled]="!submitButtonEnabled()"
              class="forgotten-password__details__button forgotten-password__details__button__right forgotten-password__details__button__primary">
              {{ 'core.forgottenPassword.button.submit' | translate }}
            </button>
            <button
              mat-raised-button
              (click)="backToLogin($event)"
              class="forgotten-password__details__button">
              {{ 'core.forgottenPassword.button.backToLogin' | translate }}
            </button>
          </form>
        </div>

        <div *ngIf="isComplete && !isError">
          <p>{{ 'core.forgottenPassword.completed.message' | translate }}</p>
          <p class="forgotten-password__details__email">{{ email }}</p>
          <a
            href="#"
            mat-raised-button
            routerLink="/login"
            class="forgotten-password__details__button forgotten-password__details__button__primary">
            {{ 'core.forgottenPassword.button.backToLogin' | translate }}
          </a>
        </div>
      </div>
    </two-tone-container>
  `
})
export class ForgottenPasswordComponent implements OnInit {
  isComplete: boolean = false;
  isSubmitting: boolean;
  isError: boolean = false;
  isEmailError: boolean = false;
  confirmFormGroup: FormGroup;

  apiErrorMessage: string;

  twoToneMessages: Array<string>;

  email: string;

  constructor (
    private authService: AuthService,
    private fb: FormBuilder,
    private router: Router,
    private translate: TranslateService
  ) {
    this.confirmFormGroup = this.fb.group({
      email: ['', Validators.pattern('^[^@]+@[^@]+\\.[^@]+$')]
    });
  }

  private handleApiError (error: Response) {
    switch (error.status) {
      case 429:
        this.translate.get('core.forgottenPassword.completed.message').subscribe((message: string) => {
        this.apiErrorMessage = message;
        });
        break;
      case 500:
      case 501:
      case 502:
      case 503:
        this.translate.get('core.forgottenPassword.error.server').subscribe((message: string) => {
          this.apiErrorMessage = message;
        });
        break;
      default:
        this.translate.get('core.forgottenPassword.error.network').subscribe((message: string) => {
          this.apiErrorMessage = message;
        });
        break;
    }
  }

  public ngOnInit (): void {
    this.translate.get('core.forgottenPassword.title.message').subscribe(message => {
      this.twoToneMessages = message;
    });

    (<FormControl>this.confirmFormGroup.controls['email']).valueChanges.subscribe(() => {
      this.isEmailError = false;
    });
  }

  public ngAfterViewInit (): void {
    this.setFocus('forgottenPasswordEmailInput');
  }

  submitEmail (event: Event) {
    event.preventDefault();
    event.stopPropagation();
    if (!this.confirmFormGroup.valid) {
      this.isEmailError = true;
      return;
    }

    this.email = (<FormControl>this.confirmFormGroup.controls['email']).value;

    this.isSubmitting = true;
    this.authService.forgottenPassword(this.email).subscribe(
      data => {
        this.isComplete = true;
        this.stopLoading();
      }, (error) => {
        this.isError = true;
        this.handleApiError(error);
        this.stopLoading();
      }
    );
  }

  submitButtonEnabled () {
    return (<FormControl>this.confirmFormGroup.controls['email']).value != '' && !this.isEmailError;
  }

  backToLogin (event: Event) {
    event.preventDefault();
    event.stopPropagation();
    this.router.navigate([ '/login' ]);
  }

  private stopLoading () {
    setTimeout(() => {
      this.isSubmitting = false;
    }, 500);
  }

  private setFocus(target) {
    setTimeout(function waitTargetElem() {
      const targetElem = document.getElementById(target);
      if (document.body.contains(targetElem)) {
        targetElem.focus();
      } else {
        setTimeout(waitTargetElem, 100);
      }
    }, 100);
  }

}
