import { Injectable } from '@angular/core';
import { CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable()
export class AppStatusService {
  private _isLoaded = new BehaviorSubject<boolean>(false);
  public isLoaded = this._isLoaded.asObservable();

  public appRouteReady (): void {
    this._isLoaded.next(true);
  }
}

@Injectable()
export class Deferred404Guard implements CanActivate {
  private hasRedirected = false;

  constructor (private appStatus: AppStatusService, private router: Router) {}

  public canActivate (_ ,state: RouterStateSnapshot): Promise<boolean> {
    // If route is not /app, 404.
    // if (state.url.substr(0, 4) !== '/app') {
      return Promise.resolve(true);
    // }

    // If we've somehow got stuck in a loop
    // (the app hasn't implemented a 404 handler), 404.
    if (this.hasRedirected) {
      console.error(new Error('GDWebCore router got stuck in a redirect loop. This is likely caused by the app not implementing a custom 404 handler.'));
      this.hasRedirected = false;
      return Promise.resolve(true);
    }

    // Otherwise, wait until the app has been loaded and resolve.
    return new Promise(resolve => {
      this.router.navigate(['holding'], { replaceUrl: false, skipLocationChange: true });

      this.appStatus.isLoaded
        .pipe(filter(value => value === true))
        .subscribe(_ => {
          this.hasRedirected = true;
          this.router.navigateByUrl(state.url);
          resolve(false);
        });
    });
  }
}
