import { Routes } from '@angular/router';
import { HoldingComponent, NotFoundComponent } from './core/core/index';
import {
  FirstTimeComponent,
  ForgottenPasswordComponent,
  LoginAuthGuard,
  LoginComponent,  
  ResetPasswordComponent
} from './core/auth/index';
import { Deferred404Guard, LocalizationGuard } from './core/core/index';




export const gloodooRoutes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
    data: {
      title: 'Login'
    },
    canActivate: [ LoginAuthGuard, LocalizationGuard ]
  }, 
  {
    path: 'firstTime',
    component: FirstTimeComponent,
    data: {
      title: 'First Time'
    },
    canActivate: [ LocalizationGuard ]
  },
  {
    path: 'resetPassword',
    component: ResetPasswordComponent,
    data: {
      title: 'Reset Password'
    },
    canActivate: [ LocalizationGuard ]
  },
  {
    path: 'forgottenPassword',
    component: ForgottenPasswordComponent,
    data: {
      title: 'Forgotten Password'
    },
    canActivate: [ LocalizationGuard ]
  },
  {
    // Root redirect
    path: '',
    pathMatch: 'full',
    redirectTo: '/login',
    data: {
      title: 'Accenture IWOS'
    }
  },
  {
    path: 'holding',
    component: HoldingComponent,
  // },
  // {
  //   // 404
  //   path: '**',
  //   component: NotFoundComponent,
  //   data: {
  //     title: 'Not found'
  //   },
  //   canActivate: [ Deferred404Guard ]
  }
];

export const gloodooViews = [
  HoldingComponent,
  LoginComponent, 
  FirstTimeComponent,
  ForgottenPasswordComponent,
  ResetPasswordComponent
];
